import React, { useEffect } from "react";
import AppInterface from "../../utils/commonComponents/AppInterface";
import { West } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import CustomTabs from "../../utils/commonComponents/common/Tabs";
import { Col, Row } from "react-bootstrap";
import ProfileCard from "../networking/components/ProfileCard";
import CustomButton from "../../utils/commonComponents/forms/CustomButton";
import { showModal } from "../home/HomeAction";
import { modals } from "../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BasicModal from "../../utils/commonComponents/Modal";
import nullimg from "../../assets/images/not-found.svg";

const SearchResults = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const location = useParams();
  const dispatch = useDispatch();
  const [current, setCurrent] = React.useState(null);
  const { search } = useSelector((state) => state.SearchState);
  const { modal, user } = useSelector((state) => state.HomeState);
  const { member, company } = search;
  const loc = useLocation();
  const [activeKey, setActiveKey] = React.useState("people");
  const params = new URLSearchParams(loc.search);

 
  useEffect(() => {
    window.addEventListener("load", () => {
      navigate("/search");
    });
  }, [navigate]);
  return (
    <AppInterface
      className={"networking-interface"}
      searchValue={location["*"].split("/").join(", ")}
            header={
        <div className="networking-header flex-row gap-12 align-center">
          <div
            className="pointer"
            onClick={() => {
              navigate(
                params.get("advanced") === "true"
                  ? "/search?advanced=true"
                  : "/search"
              );
            }}

          >
            <West></West>
          </div>
          <div>
            <h1 className="f-s-14 manrope-bold">Search Results</h1>
          </div>
        </div>
      }
    >
      {modal === modals.BOOK_MEEETING_MODAL && (
        <BasicModal
          open={modals.BOOK_MEEETING_MODAL}
          handleClose={() => dispatch(showModal(null))}
          data={{ uuid: current?.user?.uuid,user: current }}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      <div className="">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">
            <div
              className="pointer"
              onClick={() => {
                navigate(
                  params.get("advanced") === "true"
                    ? "/search?advanced=true"
                    : "/search"
                );
              }}
            >
              <West sx={{ fontSize: "24px" }}></West>
            </div>
            <div>
              <h1 className="f-s-24 manrope-bold">
                Search Results for "{location["*"].split("/").join(", ")}"
              </h1>
            </div>
          </div>
        )}
        <div className="networking-tabs-box">
          <CustomTabs
            nullImg={nullimg}
            activeKey={activeKey || "people"}
            onSelect={(key) => {
              setActiveKey(key);
            }
            }
            nullText={"No results found"}
            options={[
              {
                eventKey: "people",
                title: "People",
                children:
                  member?.length > 0 ? (
                    <Row className="mt-4 tabs-scroll">
                      {member?.map((member) => {
                        return (
                          <Col md="3" sm="12">
                            <ProfileCard
                            onClick={() =>
                              navigate(`/profile/${member.uuid}`)

                            }
                              img={member?.profileImageUrl}
                              tag={"Employee"}
                              action={
                                <div className="flex-row gap-6">
                                  <CustomButton
                                    onClick={() =>
                                      navigate(`/profile/${member.uuid}`)
                                    }
                                    className={"common-btn"}
                                  >
                                    View Profile
                                  </CustomButton>
                                  {member?.user?.uuid !==
                                    user?.member?.user?.uuid && (
                                      <CustomButton
                                        className={
                                          "common-btn bg-grey-F2F black"
                                        }
                                        onClick={() => {
                                          setCurrent(member);
                                          dispatch(
                                            showModal(
                                              modals.BOOK_MEEETING_MODAL
                                            )
                                          );
                                        }}
                                      >
                                        Book a Meeting
                                      </CustomButton>
                                    )}
                                </div>
                              }
                              heading={`${member?.firstName || "New user"} ${member?.lastName || ""
                                }`}
                              text={`${member?.company?.companyName} | ${member?.designation || ""
                                }`}
                            ></ProfileCard>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : null,
              },
              {
                eventKey: "company",
                title: "Company",
                children:
                  company?.length > 0 ? (
                    <Row className="mt-4 tabs-scroll">
                      {company?.map((company) => {
                        return (
                          <Col md="3" sm="12">
                            <ProfileCard
                            onClick={
                              () => navigate(`/company/${company.uuid}`)
                            }
                              img={company?.profileImageUrl}
                              tag={"Company"}
                              action={
                                <div className="flex-row gap-6">
                                  <CustomButton
                                    className={"common-btn"}
                                    onClick={() =>
                                      navigate(`/company/${company.uuid}`)
                                    }
                                  >
                                    View Company
                                  </CustomButton>
                                  {company?.user?.uuid !==
                                    user?.user?.uuid && (
                                      <CustomButton
                                        onClick={() => {
                                          setCurrent(company);
                                          dispatch(
                                            showModal(
                                              modals.BOOK_MEEETING_MODAL
                                            )
                                          );
                                        }}
                                        className={
                                          "common-btn bg-grey-F2F black"
                                        }
                                      >
                                        Book a Meeting
                                      </CustomButton>
                                    )}
                                </div>
                              }
                              heading={company?.companyName}
                              text={`${company?.contactPerson} | ${company?.designation}`}
                            ></ProfileCard>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : null,
              },
            ]}
          ></CustomTabs>
        </div>
      </div >
    </AppInterface >
  );
};

export default SearchResults;
