import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { styled } from "@mui/material/styles";
import { PickersLayout } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import dayjs from "dayjs";

// Updated picker layout with the new background color and selected state styles
const StyledPickersLayout = styled(PickersLayout)({
    ".MuiDateCalendar-root": {
        backgroundColor: "#F9F9F9",
    },
    ".MuiPickersDay-root": {
        fontSize: "16px",
        fontFamily: "Manrope-Regular, sans-serif",
        ":hover": {
            borderColor: "#0E1596",
        },
        ":focus": {
            borderColor: "#0E1596",
        },
        "&.Mui-selected": {
            backgroundColor: "#0E1596",
            fontWeight: "600",
            color: "white",
            ":hover": {
                backgroundColor: "#0E1596",
            },
            ":focus": {
                backgroundColor: "#0E1596",
            },
        },
    },
    ".MuiPickersCalendarHeader-label": {
        fontSize: "15px",
        fontFamily: "Manrope-Semibold, sans-serif",
        color: "black",
    },
    ".MuiDayCalendar-weekDayLabel": {
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.60)",  // Weekday label color (faded white)
        fontFamily: "Manrope-Regular, sans-serif",
    },
    ".MuiIconButton-root": {
        color: "white",  // Icon color
        ":hover": {
            borderColor: "#0E1596",
        },
        ":focus": {
            backgroundColor: "gray",
        },
    },
});

const StyledTextField = styled(TextField)({
    borderRadius: "20px",
    fontFamily: "Manrope-Regular, sans-serif",
    fontSize: "16px",
    width: "100%",
    backgroundColor: "#F2F2F2",
    borderWidth: "0 !important",
    ".MuiInputBase-root": {
        width: "100%",
        backgroundColor: "#F2F2F2",
        borderRadius: "20px",
        input: {
            padding: "0 16px",
            height: "40px",
            borderRadius: "20px",
            border: "none",  // Remove any border
            background: "none",
            outline: "none",  // Remove outline
            boxShadow: "none", // Remove box shadow
            fontSize: "16px",
        },
        "&:hover": {
            borderColor: "transparent", // Ensure no border on hover
        },
        "&.Mui-focused": {
            borderColor: "transparent", // Remove border when focused
            boxShadow: "none",  // Remove box shadow when focused
        },
    },
    ".MuiOutlinedInput-notchedOutline": {
        border: "none", // Remove the outlined border
    },
    ".MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
});


export default function BasicDatePicker({
    label,
    beforeDisabled = null,
    afterDisabled = null,
    value,
    onChange,
    round
}) {
    const shouldDisableDate = (date) => {
        if (beforeDisabled) {
            return dayjs(date).isBefore(dayjs(beforeDisabled), "day");
        }
        if (afterDisabled) {
            return dayjs(date).isAfter(dayjs(afterDisabled), "day");
        }
        return false;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
                className="custom-date-picker"
                slots={{
                    layout: StyledPickersLayout,
                    textField: StyledTextField,
                }}
                sx={round ? { borderRadius: round } : {}}
                value={value && dayjs(value)}
                onChange={(e) => onChange(e.format("YYYY-MM-DD"))}
                label={label}
                shouldDisableDate={shouldDisableDate}
            />
        </LocalizationProvider>
    );
}