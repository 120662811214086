import { GET_COMPANIES_FAILURE, GET_COMPANIES_REQUEST, GET_COMPANIES_SUCCESS, GET_MEMBERS_FAILURE, GET_MEMBERS_REQUEST, GET_MEMBERS_SUCCESS } from "./ActionTypes";
const INITIAL_STATE = {
    members: {
        data: [],
        loading: false,
        length: 0,
    },
    companies: {
        data: [],
        loading: false,
        length: 0,
    },
    error: null,
    loading: false,
};
const NetworkingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MEMBERS_REQUEST:
            return {
                ...state,
                members: {
                    ...state.members,
                    loading: true,
                },
            };
        case GET_MEMBERS_SUCCESS:
            return {
                ...state,
                members: {
                    data: action.payload.list,
                    loading: false,
                    length: action.payload.length,
                },
            };
        case GET_COMPANIES_REQUEST:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    loading: true,
                },
            };
        case GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: {
                    data: action.payload.list,
                    loading: false,
                    length: action.payload.length,
                },
            };
        case GET_COMPANIES_FAILURE:
        case GET_MEMBERS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state
    }
};
export default NetworkingReducer