import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Email, Phone } from "@mui/icons-material";
import bg from "../../../assets/images/common-bg-mobile.png";
import postLoginInstance from "../../../utils/PostLoginAxios";
import { toast } from "react-toastify";
import { getUserProfile } from "../../home/HomeAction";
import { useDispatch } from "react-redux";
import ImageCropper from "../../../utils/commonComponents/ImageCropper";
import BasicModal from "./../../../utils/commonComponents/Modal";

const CompanyProfile = ({
  img,
  heading,
  text,
  background,
  phone,
  email,
  tip,
  edit = false,
}) => {
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(null); // For cropping
  const [croppedImage, setCroppedImage] = useState(background || bg); // Store cropped image
  const inputRef = useRef();
  // Trigger file input click
  const handleClick = () => {
    inputRef && inputRef.current.click();
  };

  // Handle image selection
  const handleImageChange = (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!validImageTypes.includes(file.type)) {
      return toast.warn("Please upload a valid image file (JPEG, PNG, GIF)");
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result); // Load the image into the cropper
    };
    reader.readAsDataURL(file);
  };

  // Handle image cropping
  const handleCropComplete = async (croppedDataUrl) => {
    setImageSrc(null); // Close cropper
    setCroppedImage(croppedDataUrl); // Set cropped image

    // Convert base64/URL to Blob
    const response = await fetch(croppedDataUrl);
    const blob = await response.blob();

    // Convert Blob to File object
    const file = new File([blob], "bannerImage.jpg", { type: blob.type });

    // Upload cropped image to the server
    const formData = new FormData();
    formData.append("bannerImage", file);

    try {
      await postLoginInstance.patch("users/edit-banner", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getUserProfile());
      toast.success("Banner image updated successfully!");
    } catch (error) {
      console.error("Error uploading cropped image:", error);
      toast.error("Failed to upload image.");
    }
  };

  const handleCancelCrop = () => {
    setImageSrc(null); // Close cropper without saving
  };

  return (
    <>
      {imageSrc && (
        <BasicModal open={imageSrc} handleClose={() => setImageSrc("")}>
          <ImageCropper
            imageSrc={imageSrc}
            aspect={4}
            onCropComplete={handleCropComplete}
            onCancel={handleCancelCrop}
          />
        </BasicModal>
      )}
      <div className="home-company-profile">
        <div className="company-bg">
          <img
            src={edit ? croppedImage : background ? background : bg}
            alt="profile banner"
          />
          {edit && (
            <>
              <div
                title="Edit Image"
                className="edit-icon"
                onClick={handleClick}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_b_1430_1135)">
                    <circle
                      cx="16"
                      cy="16"
                      r="16"
                      fill="black"
                      fill-opacity="0.43"
                    />
                  </g>
                  <path
                    d="M9 23H10.425L20.2 13.225L18.775 11.8L9 21.575V23ZM7 25V20.75L20.2 7.575C20.4 7.39167 20.621 7.25 20.863 7.15C21.105 7.05 21.359 7 21.625 7C21.891 7 22.1493 7.05 22.4 7.15C22.6507 7.25 22.8673 7.4 23.05 7.6L24.425 9C24.625 9.18333 24.771 9.4 24.863 9.65C24.955 9.9 25.0007 10.15 25 10.4C25 10.6667 24.9543 10.921 24.863 11.163C24.7717 11.405 24.6257 11.6257 24.425 11.825L11.25 25H7ZM19.475 12.525L18.775 11.8L20.2 13.225L19.475 12.525Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_b_1430_1135"
                      x="-4"
                      y="-4"
                      width="40"
                      height="40"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="2"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_1430_1135"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_1430_1135"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>

                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  className="d-none"
                  onChange={handleImageChange}
                />
              </div>
              <div
                title="Delete Image"
                className="default-icon"
                onClick={async () => {
                  try {
                    const response = await fetch(bg);
                    const blob = await response.blob();

                    const file = new File([blob], "currentBannerImage.jpg", {
                      type: blob.type,
                    });

                    const formData = new FormData();
                    formData.append("bannerImage", file);

                    await postLoginInstance.patch(
                      "users/edit-banner",
                      formData,
                      {
                        headers: { "Content-Type": "multipart/form-data" },
                      }
                    );

                    dispatch(getUserProfile());
                    toast.success("Default Banner Applied Successfully!");
                  } catch (error) {
                    console.error("Error uploading the current image:", error);
                    toast.error("Failed to upload the current image.");
                  }
                }}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_b_1430_1132)">
                    <circle
                      cx="16"
                      cy="16"
                      r="16"
                      fill="black"
                      fill-opacity="0.43"
                    />
                  </g>
                  <path
                    d="M11 25C10.45 25 9.97933 24.8043 9.588 24.413C9.19667 24.0217 9.00067 23.5507 9 23V10H8V8H13V7H19V8H24V10H23V23C23 23.55 22.8043 24.021 22.413 24.413C22.0217 24.805 21.5507 25.0007 21 25H11ZM21 10H11V23H21V10ZM13 21H15V12H13V21ZM17 21H19V12H17V21Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_b_1430_1132"
                      x="-4"
                      y="-4"
                      width="40"
                      height="40"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="2"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_1430_1132"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_1430_1132"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </>
          )}
        </div>
        <div className="company-main px-0">
          <Row>
            <Col md="12" className="relative media">
              <div className="company-logo">
                <img src={img} alt="company logo" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="flex-row justify-space-between align-center p-md-4 mt-5 main-content">
                <div className="profile">
                  <h4 className="f-s-16 manrope-medium">{heading}</h4>
                  <p className="f-s-14 manrope-regular">{text}</p>
                </div>
                <Row className="gap-25">
                  <Col>
                    <div className="display-chip flex-row gap-2 align-center">
                      <Phone />
                      <p
                        onClick={() => {
                          if (phone === "12345678") return;
                          window.open(`tel:${phone}`);
                        }}
                        className="f-s-16 manrope-regular pointer"
                      >
                        {phone}
                      </p>
                    </div>
                  </Col>

                  <Col>
                    <div className="display-chip flex-row gap-2 align-center">
                      <Email />
                      <p
                        onClick={() => {
                          if (email === "xyz@gmail.com") return;
                          window.open(`mailto:${email}`);
                        }}
                        className="f-s-16 manrope-regular pointer"
                      >
                        {email}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              {tip && (
                <div className="text-right profile-tip mb-2 me-5">
                  <p className="error blue-0E1">{tip}</p>
                </div>
              )}
              <hr />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
