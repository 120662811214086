export const GET_MEMBER_REQUEST = "GET_MEMBER_REQUEST";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_MEMBER_FAILURE = "GET_MEMBER_FAILURE";

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SEND_MEETING_REQUEST = "SEND_MEETING_REQUEST";
export const SEND_MEETING_SUCCESS = "SEND_MEETING_SUCCESS";
export const SEND_MEETING_FAILURE = "SEND_MEETING_FAILURE";

export const RESET_SEND_MEETING = "RESET_SEND_MEETING";