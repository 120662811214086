import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CompanyProfile from "./CompanyProfile";
import Recommendations from "./Recommendations";
import AdsCarousel from "./AdsCarousel";
import ReferInvite from "./ReferInvite";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyRecommendations, getHomeBanners, getHomeEvents, getMemberRecommendations } from "../HomeAction";
import { DefaultBanner, DefaultEvents } from "./Default";

const Main = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const { ads, user } = useSelector((state) => state.HomeState);

  useEffect(() => {
    dispatch(getHomeBanners());
    dispatch(getHomeEvents());
    dispatch(getMemberRecommendations());
    dispatch(getCompanyRecommendations())
  }, [])
  if (isMobile) {
    return (
      <div className="mobile-home-main-contianer">
        <Row>
          <Col className="my-4">
            <AdsCarousel data={ads.banner.list} empty={<DefaultBanner />} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Recommendations />
          </Col>
        </Row>
        <Row>
          <Col className="my-4">
            <ReferInvite />
          </Col>
        </Row>
        <Row>
          <Col className="my-4">
            <AdsCarousel data={ads.events.list} small={true} empty={<DefaultEvents />} />
          </Col>
        </Row>
      </div>
    );
  }
  if (!isMobile) {
    return (
      <div className="desktop-home-main-container ">
        <Row>
          <Col md="3" className="pe-0">
            <Row className="profile-row">
              <Col className="my-3 ps-3 ">
                <CompanyProfile background={user?.role === "company" ? user?.bannerUrl : user?.member?.bannerUrl} img={user?.role === "company" ? user?.profileImageUrl : user?.member?.profileImageUrl} uuid={user?.role === "company" ? user.uuid : user?.member?.company?.uuid} text={user.role === "company" ? user?.contactPerson : `${user?.member?.company?.companyName || "New User"}`} heading={user?.role === "company" ? user?.companyName : `${user?.member?.firstName} ${user?.member?.lastName}`} />
              </Col>
            </Row>
            <Row className="recommend-row">
              <Col  className=" my-3 ps-3">
                <Recommendations />
              </Col>
            </Row>
          </Col>
          <Col md="9">
            <Row>
              <Col className="my-3 me-1">
                <AdsCarousel data={ads.banner.list} empty={<DefaultBanner />} />
              </Col>
            </Row>
            <Row>
              <Col md="8" className="my-3">
                <div className="event-ads-carousel">
                  <AdsCarousel data={ads.events.list} small empty={<DefaultEvents />} />
                </div>
              </Col>
              <Col md="4" className="my-3  pe-3">
                <ReferInvite />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
};

export default Main;
