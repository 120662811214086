import BannerMobile from "../../../assets/images/default-banner-mobile.png";
import BannerDesktop from "../../../assets/images/default-banner-desktop.png";
import { useMediaQuery } from "@mui/material";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useNavigate } from "react-router-dom";

export const DefaultBanner = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="default-banner">
      <img src={isMobile ? BannerMobile : BannerDesktop} alt="" />
    </div>
  );
};

export const DefaultEvents = () => {
  const navigate = useNavigate();
  return (
    <div className="default-events flex-row align-center justify-center gap-50">
      <div>
        <svg
          width="162"
          height="162"
          viewBox="0 0 162 162"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M162 123.897H0V123.978H162V123.897Z" fill="#EBEBEB" />
          <path
            d="M145.767 129.11H117.612V129.191H145.767V129.11Z"
            fill="#EBEBEB"
          />
          <path
            d="M118.697 126.104H115.882V126.185H118.697V126.104Z"
            fill="#EBEBEB"
          />
          <path
            d="M96.9763 128.79H90.7588V128.871H96.9763V128.79Z"
            fill="#EBEBEB"
          />
          <path
            d="M23.9955 126.648H16.9971V126.729H23.9955V126.648Z"
            fill="#EBEBEB"
          />
          <path
            d="M35.9282 126.648H26.8789V126.729H35.9282V126.648Z"
            fill="#EBEBEB"
          />
          <path
            d="M82.4613 127.977H67.2852V128.058H82.4613V127.977Z"
            fill="#EBEBEB"
          />
          <path
            d="M76.7878 109.447H14.2267C13.7366 109.446 13.2669 109.251 12.9206 108.904C12.5744 108.557 12.3799 108.087 12.3799 107.597V19.6537C12.3841 19.1664 12.5805 18.7004 12.9263 18.3571C13.2721 18.0137 13.7394 17.8207 14.2267 17.8198H76.7878C77.2785 17.8198 77.7491 18.0147 78.096 18.3617C78.443 18.7086 78.6379 19.1792 78.6379 19.6699V107.597C78.6379 108.088 78.443 108.558 78.096 108.905C77.7491 109.252 77.2785 109.447 76.7878 109.447ZM14.2267 17.8846C13.7581 17.8855 13.3089 18.0722 12.9779 18.4039C12.6468 18.7356 12.4609 19.185 12.4609 19.6537V107.597C12.4609 108.066 12.6468 108.515 12.9779 108.847C13.3089 109.178 13.7581 109.365 14.2267 109.366H76.7878C77.2568 109.365 77.7062 109.179 78.0378 108.847C78.3694 108.515 78.556 108.066 78.5569 107.597V19.6537C78.556 19.1847 78.3694 18.7353 78.0378 18.4037C77.7062 18.0721 77.2568 17.8855 76.7878 17.8846H14.2267Z"
            fill="#EBEBEB"
          />
          <path
            d="M146.872 109.447H84.308C83.8176 109.446 83.3476 109.251 83.0008 108.904C82.6541 108.557 82.4589 108.087 82.458 107.597V19.6537C82.4631 19.1661 82.6602 18.7002 83.0065 18.3569C83.3528 18.0136 83.8204 17.8206 84.308 17.8198H146.872C147.359 17.8215 147.826 18.015 148.171 18.3582C148.516 18.7015 148.712 19.1669 148.716 19.6537V107.597C148.716 108.087 148.522 108.556 148.176 108.903C147.831 109.25 147.362 109.445 146.872 109.447ZM84.308 17.8846C83.8391 17.8855 83.3897 18.0721 83.0581 18.4037C82.7265 18.7353 82.5399 19.1847 82.539 19.6537V107.597C82.5399 108.066 82.7265 108.515 83.0581 108.847C83.3897 109.179 83.8391 109.365 84.308 109.366H146.872C147.341 109.365 147.791 109.179 148.122 108.847C148.454 108.515 148.641 108.066 148.641 107.597V19.6537C148.641 19.1847 148.454 18.7353 148.122 18.4037C147.791 18.0721 147.341 17.8855 146.872 17.8846H84.308Z"
            fill="#EBEBEB"
          />
          <path
            d="M67.5991 23.6323H17.499V95.2784H67.5991V23.6323Z"
            fill="#E6E6E6"
          />
          <path
            d="M69.1026 23.6323H18.2021V95.2784H69.1026V23.6323Z"
            fill="#F0F0F0"
          />
          <path
            d="M67.5991 95.2783H17.499V96.5905H67.5991V95.2783Z"
            fill="#E6E6E6"
          />
          <path
            d="M69.9028 95.2783H19.0024V96.5905H69.9028V95.2783Z"
            fill="#F0F0F0"
          />
          <path
            d="M67 93.1727V25.7354L20.3084 25.7354L20.3084 93.1727H67Z"
            fill="#FAFAFA"
          />
          <path
            d="M41.8804 93.1724L53.2074 25.7383H59.0394L47.7124 93.1724H41.8804Z"
            fill="white"
          />
          <path
            d="M27.1641 93.1724L38.4911 25.7383H44.3231L32.9961 93.1724H27.1641Z"
            fill="white"
          />
          <path
            d="M21.2803 59.3241C21.1929 59.3241 21.1216 59.2172 21.1216 59.0843V27.1833C21.1216 27.0505 21.1929 26.9468 21.2803 26.9468C21.3678 26.9468 21.4423 27.0505 21.4423 27.1833V59.0843C21.4423 59.2172 21.384 59.3241 21.2803 59.3241Z"
            fill="#F0F0F0"
          />
          <path
            d="M48.9663 93.1724L60.2933 25.7383H62.5646L51.2375 93.1724H48.9663Z"
            fill="white"
          />
          <path d="M67 93.1756V25.7383H66.7343V93.1756H67Z" fill="#E6E6E6" />
          <path
            opacity="0.5"
            d="M69.6017 31.8197H19.5015L19.3071 26.5547H69.4073L69.6017 31.8197Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 40.4707H19.5015L19.3071 35.209H69.4073L69.6017 40.4707Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 49.1245H19.5015L19.3071 43.8628H69.4073L69.6017 49.1245Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 57.7787H19.5015L19.3071 52.5137H69.4073L69.6017 57.7787Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 66.4297H19.5015L19.3071 61.168H69.4073L69.6017 66.4297Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 75.0835H19.5015L19.3071 69.8218H69.4073L69.6017 75.0835Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 36.1454H19.5015L19.3071 30.8804H69.4073L69.6017 36.1454Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 44.7992H19.5015L19.3071 39.5342H69.4073L69.6017 44.7992Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 53.4502H19.5015L19.3071 48.1885H69.4073L69.6017 53.4502Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 62.104H19.5015L19.3071 56.8423H69.4073L69.6017 62.104Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 70.7582H19.5015L19.3071 65.4932H69.4073L69.6017 70.7582Z"
            fill="#E0E0E0"
          />
          <path
            opacity="0.5"
            d="M69.6017 79.4125H19.5015L19.3071 74.1475H69.4073L69.6017 79.4125Z"
            fill="#E0E0E0"
          />
          <path
            d="M96.2508 72.7539H86.2651V122.796H96.2508V72.7539Z"
            fill="#F0F0F0"
          />
          <path
            d="M137.425 122.796H88.1831V123.897H137.425V122.796Z"
            fill="#F0F0F0"
          />
          <path
            d="M96.2477 122.796H139.336V72.7541H96.2477V122.796Z"
            fill="#F5F5F5"
          />
          <path
            d="M134.382 100.948H101.201C100.886 100.947 100.584 100.822 100.361 100.599C100.139 100.376 100.013 100.074 100.012 99.7593V92.0157C100.012 91.7 100.137 91.3972 100.36 91.1737C100.583 90.9502 100.886 90.8242 101.201 90.8233H106.687C106.908 90.8268 107.124 90.8912 107.311 91.0096C107.498 91.1279 107.648 91.2956 107.746 91.494C107.853 91.6906 108.01 91.8546 108.203 91.9687C108.395 92.0828 108.614 92.1427 108.838 92.142H126.752C127.079 92.1413 127.393 92.0108 127.623 91.7791C127.717 91.6834 127.796 91.5742 127.857 91.4551C127.955 91.2605 128.105 91.0969 128.29 90.9824C128.475 90.868 128.689 90.8073 128.907 90.8071H134.382C134.698 90.8071 135.002 90.9327 135.225 91.1564C135.449 91.38 135.574 91.6832 135.574 91.9995V99.7593C135.574 100.075 135.448 100.377 135.224 100.6C135.001 100.823 134.698 100.948 134.382 100.948ZM101.201 91.1506C100.972 91.1514 100.752 91.2433 100.589 91.406C100.427 91.5688 100.336 91.7892 100.336 92.0189V99.7593C100.337 99.9884 100.428 100.208 100.591 100.37C100.753 100.532 100.972 100.623 101.201 100.624H134.382C134.612 100.624 134.832 100.533 134.995 100.371C135.158 100.209 135.25 99.989 135.25 99.7593V92.0157C135.25 91.7854 135.159 91.5645 134.996 91.4017C134.833 91.2388 134.612 91.1473 134.382 91.1473H128.907C128.75 91.1468 128.596 91.1895 128.461 91.2706C128.327 91.3518 128.218 91.4683 128.145 91.6074C128.069 91.7574 127.97 91.8941 127.85 92.0124C127.706 92.1572 127.535 92.2721 127.347 92.3505C127.158 92.4289 126.956 92.4693 126.752 92.4693H108.838C108.555 92.4694 108.278 92.393 108.036 92.2481C107.793 92.1032 107.595 91.8952 107.461 91.6463C107.39 91.5002 107.28 91.3765 107.144 91.2885C107.007 91.2005 106.849 91.1517 106.687 91.1473L101.201 91.1506Z"
            fill="#EBEBEB"
          />
          <path
            d="M134.382 114.469H101.201C100.886 114.468 100.584 114.343 100.361 114.12C100.139 113.897 100.013 113.595 100.012 113.28V105.54C100.012 105.224 100.137 104.921 100.36 104.698C100.583 104.474 100.886 104.348 101.201 104.347H106.687C106.908 104.351 107.124 104.415 107.311 104.533C107.498 104.652 107.648 104.819 107.746 105.018C107.853 105.214 108.01 105.379 108.203 105.493C108.395 105.607 108.614 105.667 108.838 105.666H126.752C126.914 105.667 127.074 105.635 127.223 105.572C127.372 105.51 127.507 105.419 127.62 105.303C127.716 105.208 127.796 105.099 127.857 104.979C127.955 104.784 128.105 104.621 128.29 104.506C128.475 104.392 128.689 104.331 128.907 104.331H134.382C134.698 104.331 135.002 104.457 135.225 104.68C135.449 104.904 135.574 105.207 135.574 105.523V113.264C135.576 113.421 135.547 113.578 135.487 113.724C135.428 113.87 135.341 114.003 135.23 114.115C135.119 114.227 134.987 114.316 134.841 114.377C134.696 114.438 134.54 114.469 134.382 114.469ZM101.201 104.671C100.972 104.672 100.752 104.764 100.589 104.927C100.427 105.089 100.336 105.31 100.336 105.54V113.28C100.337 113.509 100.428 113.729 100.591 113.891C100.753 114.053 100.972 114.144 101.201 114.145H134.382C134.612 114.145 134.832 114.054 134.995 113.892C135.158 113.73 135.25 113.51 135.25 113.28V105.54C135.25 105.309 135.159 105.088 134.996 104.926C134.833 104.763 134.612 104.671 134.382 104.671H128.907C128.75 104.671 128.596 104.713 128.461 104.795C128.327 104.876 128.218 104.992 128.145 105.131C128.069 105.281 127.97 105.418 127.85 105.536C127.707 105.682 127.536 105.797 127.347 105.876C127.159 105.954 126.956 105.994 126.752 105.993H108.838C108.555 105.993 108.278 105.917 108.036 105.772C107.793 105.627 107.595 105.419 107.461 105.17C107.39 105.024 107.28 104.9 107.144 104.812C107.007 104.724 106.849 104.676 106.687 104.671H101.201Z"
            fill="#EBEBEB"
          />
          <path
            d="M135.574 120.489H100.012V117.612H135.574V120.489ZM100.336 120.165H135.25V117.936H100.336V120.165Z"
            fill="#EBEBEB"
          />
          <path
            d="M134.382 87.4312H101.201C100.886 87.4304 100.584 87.3048 100.361 87.082C100.139 86.8592 100.013 86.5573 100.012 86.2422V78.5018C100.012 78.1861 100.137 77.8834 100.36 77.6598C100.583 77.4363 100.886 77.3103 101.201 77.3095H106.687C106.907 77.3126 107.123 77.3766 107.31 77.4943C107.497 77.6121 107.648 77.7791 107.746 77.9769C107.852 78.1737 108.01 78.3379 108.202 78.4521C108.395 78.5662 108.614 78.6259 108.838 78.6249H126.752C127.079 78.6242 127.393 78.4937 127.623 78.262C127.717 78.1663 127.796 78.0571 127.857 77.938C127.955 77.7434 128.105 77.5798 128.29 77.4654C128.475 77.3509 128.689 77.2902 128.907 77.29H134.382C134.539 77.29 134.694 77.3209 134.838 77.3808C134.983 77.4407 135.115 77.5285 135.225 77.6393C135.336 77.75 135.424 77.8814 135.484 78.0261C135.544 78.1707 135.574 78.3258 135.574 78.4824V86.2227C135.577 86.3807 135.547 86.5374 135.488 86.684C135.429 86.8305 135.342 86.9639 135.231 87.0763C135.12 87.1888 134.988 87.278 134.842 87.339C134.696 87.3999 134.54 87.4313 134.382 87.4312ZM101.201 77.6335C100.972 77.6343 100.752 77.7262 100.589 77.8889C100.427 78.0517 100.336 78.2721 100.336 78.5018V86.2422C100.337 86.4713 100.428 86.6909 100.591 86.8529C100.753 87.015 100.972 87.1064 101.201 87.1072H134.382C134.612 87.1072 134.832 87.0162 134.995 86.8541C135.158 86.6919 135.25 86.4719 135.25 86.2422V78.5018C135.25 78.2715 135.159 78.0506 134.996 77.8878C134.833 77.725 134.612 77.6335 134.382 77.6335H128.907C128.75 77.633 128.596 77.6756 128.461 77.7567C128.327 77.8379 128.218 77.9544 128.145 78.0936C128.069 78.2435 127.97 78.3803 127.85 78.4986C127.707 78.6436 127.535 78.7587 127.347 78.8371C127.158 78.9156 126.956 78.9558 126.752 78.9554H108.838C108.556 78.9552 108.278 78.8787 108.036 78.7338C107.794 78.5889 107.595 78.3811 107.461 78.1324C107.39 77.9864 107.28 77.8626 107.144 77.7747C107.007 77.6867 106.849 77.6378 106.687 77.6335H101.201Z"
            fill="#EBEBEB"
          />
          <path
            d="M140.671 71.9473H96.2505V72.754H140.671V71.9473Z"
            fill="#F0F0F0"
          />
          <path
            d="M84.8716 72.7539H96.2505V71.9471H84.8716V72.7539Z"
            fill="#E6E6E6"
          />
          <path d="M131 25.3594H92.8877V63.1864H131V25.3594Z" fill="#E6E6E6" />
          <path
            d="M133.057 25.3594H93.2764V63.1864H133.057V25.3594Z"
            fill="#F0F0F0"
          />
          <path
            d="M131.22 61.3524V27.1963L95.1137 27.1963V61.3524H131.22Z"
            fill="white"
          />
          <path
            d="M113.167 58.3361C110.385 58.3361 107.667 57.5114 105.354 55.9663C103.042 54.4212 101.24 52.2251 100.175 49.6556C99.1111 47.0862 98.8326 44.2589 99.3752 41.5312C99.9177 38.8035 101.257 36.298 103.224 34.3314C105.19 32.3649 107.696 31.0257 110.423 30.4831C113.151 29.9405 115.978 30.219 118.548 31.2833C121.117 32.3476 123.313 34.1499 124.858 36.4623C126.403 38.7747 127.228 41.4934 127.228 44.2745C127.224 48.0025 125.741 51.5767 123.105 54.2128C120.469 56.8489 116.895 58.3318 113.167 58.3361ZM113.167 30.4559C110.434 30.4559 107.762 31.2663 105.489 32.7848C103.217 34.3032 101.446 36.4613 100.4 38.9863C99.354 41.5114 99.0803 44.2898 99.6135 46.9704C100.147 49.6509 101.463 52.1132 103.395 54.0457C105.328 55.9783 107.79 57.2944 110.471 57.8276C113.151 58.3608 115.93 58.0871 118.455 57.0412C120.98 55.9953 123.138 54.2242 124.656 51.9517C126.175 49.6792 126.985 47.0076 126.985 44.2745C126.982 40.6106 125.525 37.0978 122.934 34.5071C120.343 31.9163 116.83 30.4593 113.167 30.4559Z"
            fill="#F5F5F5"
          />
          <path
            d="M81 138.53C115.695 138.53 143.82 136.888 143.82 134.862C143.82 132.836 115.695 131.194 81 131.194C46.3053 131.194 18.1797 132.836 18.1797 134.862C18.1797 136.888 46.3053 138.53 81 138.53Z"
            fill="#F5F5F5"
          />
          <path
            d="M21.9609 116.504H101.305L107.008 67.1426H27.6666L21.9609 116.504Z"
            fill="#407BFF"
          />
          <path
            opacity="0.4"
            d="M21.9609 116.504H101.305L107.008 67.1426H27.6666L21.9609 116.504Z"
            fill="white"
          />
          <path
            d="M116.89 118.354H37.5453L27.6665 67.1426H107.008L116.89 118.354Z"
            fill="#407BFF"
          />
          <path
            opacity="0.6"
            d="M116.89 118.354H37.5453L27.6665 67.1426H107.008L116.89 118.354Z"
            fill="white"
          />
          <path
            d="M113.85 115.606H39.5216L31.5415 74.228H105.87L113.85 115.606Z"
            fill="white"
          />
          <g opacity="0.5">
            <path
              d="M45.3085 84.2623H37.5358L36.0356 76.4863H43.8084L45.3085 84.2623Z"
              fill="#407BFF"
            />
            <path
              d="M103.748 84.2623H95.9725L94.4756 76.4863H102.248L103.748 84.2623Z"
              fill="#407BFF"
            />
            <path
              d="M94.0058 84.2623H86.233L84.7329 76.4863H92.5089L94.0058 84.2623Z"
              fill="#407BFF"
            />
            <path
              d="M84.266 84.2623H76.4933L74.9932 76.4863H82.7692L84.266 84.2623Z"
              fill="#407BFF"
            />
            <path
              d="M74.5268 84.2623H66.754L65.2539 76.4863H73.0267L74.5268 84.2623Z"
              fill="#407BFF"
            />
            <path
              d="M64.7875 84.2623H57.0148L55.5146 76.4863H63.2874L64.7875 84.2623Z"
              fill="#407BFF"
            />
            <path
              d="M55.0478 84.2623H47.275L45.7749 76.4863H53.5477L55.0478 84.2623Z"
              fill="#407BFF"
            />
            <path
              opacity="0.5"
              d="M47.1782 93.9564H39.4054L37.9053 86.1836H45.678L47.1782 93.9564Z"
              fill="#407BFF"
            />
            <path
              d="M105.618 93.9564H97.8453L96.3452 86.1836H104.118L105.618 93.9564Z"
              fill="#407BFF"
            />
            <path
              opacity="0.5"
              d="M95.8784 93.9564H88.1056L86.6055 86.1836H94.3782L95.8784 93.9564Z"
              fill="#407BFF"
            />
            <path
              d="M86.1391 93.9564H78.3631L76.8662 86.1836H84.639L86.1391 93.9564Z"
              fill="#407BFF"
            />
            <path
              d="M76.3964 93.9564H68.6237L67.1235 86.1836H74.8995L76.3964 93.9564Z"
              fill="#407BFF"
            />
            <path
              d="M66.6567 93.9564H58.8839L57.3838 86.1836H65.1566L66.6567 93.9564Z"
              fill="#407BFF"
            />
            <path
              d="M56.9174 93.9564H49.1446L47.6445 86.1836H55.4173L56.9174 93.9564Z"
              fill="#407BFF"
            />
            <path
              d="M49.0473 103.651H41.2745L39.7744 95.8779H47.5472L49.0473 103.651Z"
              fill="#407BFF"
            />
            <path
              d="M107.487 103.651H99.7145L98.2144 95.8779H105.987L107.487 103.651Z"
              fill="#407BFF"
            />
            <path
              d="M97.748 103.651H89.9752L88.4751 95.8779H96.2479L97.748 103.651Z"
              fill="#407BFF"
            />
            <path
              d="M88.0087 103.651H80.236L78.7358 95.8779H86.5086L88.0087 103.651Z"
              fill="#407BFF"
            />
            <path
              d="M78.269 103.651H70.493L68.9961 95.8779H76.7689L78.269 103.651Z"
              fill="#407BFF"
            />
            <path
              d="M68.5263 103.651H60.7535L59.2534 95.8779H67.0294L68.5263 103.651Z"
              fill="#407BFF"
            />
            <path
              d="M58.787 103.651H51.0143L49.5142 95.8779H57.2869L58.787 103.651Z"
              fill="#407BFF"
            />
            <path
              d="M50.9169 113.348H43.1442L41.644 105.572H49.42L50.9169 113.348Z"
              fill="#407BFF"
            />
            <path
              d="M109.357 113.348H101.584L100.084 105.572H107.857L109.357 113.348Z"
              fill="#407BFF"
            />
            <path
              d="M99.6176 113.348H91.8448L90.3447 105.572H98.1175L99.6176 113.348Z"
              fill="#407BFF"
            />
            <path
              d="M89.8779 113.348H82.1051L80.605 105.572H88.3777L89.8779 113.348Z"
              fill="#407BFF"
            />
            <path
              d="M80.1386 113.348H72.3658L70.8657 105.572H78.6385L80.1386 113.348Z"
              fill="#407BFF"
            />
            <path
              d="M70.3989 113.348H62.6229L61.126 105.572H68.8987L70.3989 113.348Z"
              fill="#407BFF"
            />
            <path
              opacity="0.5"
              d="M60.6596 113.348H52.8836L51.3867 105.572H59.1595L60.6596 113.348Z"
              fill="#407BFF"
            />
          </g>
          <path
            d="M37.4514 70.6578L37.351 70.1426C38.2679 70.1426 38.7798 68.7688 38.4656 67.1424C38.1513 65.5159 37.1112 64.1389 36.1976 64.1389C35.2839 64.1389 34.7687 65.5159 35.0798 67.1424H34.5678C34.1855 65.1692 34.8594 63.627 36.0971 63.627C37.3348 63.627 38.6049 65.1692 38.984 67.1424C39.363 69.1155 38.6891 70.6578 37.4514 70.6578Z"
            fill="#263238"
          />
          <path
            d="M45.1238 70.6578L45.0234 70.1426C45.9403 70.1426 46.4522 68.7688 46.1379 67.1424C45.8237 65.5159 44.7836 64.1389 43.8699 64.1389C42.9563 64.1389 42.4411 65.5159 42.7521 67.1424H42.2402C41.8579 65.1692 42.5318 63.627 43.7695 63.627C45.0072 63.627 46.2773 65.1692 46.6563 67.1424C47.0354 69.1155 46.3615 70.6578 45.1238 70.6578Z"
            fill="#263238"
          />
          <path
            d="M52.812 70.6578L52.7115 70.1426C53.6285 70.1426 54.1404 68.7688 53.8261 67.1424C53.5118 65.5159 52.4718 64.1389 51.5581 64.1389C50.6444 64.1389 50.126 65.5159 50.4403 67.1424H49.896C49.5137 65.1692 50.1876 63.627 51.4253 63.627C52.6629 63.627 53.933 65.1692 54.3121 67.1424C54.6912 69.1155 54.0335 70.6578 52.812 70.6578Z"
            fill="#263238"
          />
          <path
            d="M60.4653 70.6578L60.3681 70.1426C61.285 70.1426 61.7969 68.7688 61.4826 67.1424C61.1683 65.5159 60.1283 64.1389 59.2146 64.1389C58.3009 64.1389 57.7858 65.5159 58.1001 67.1424H57.5849C57.2058 65.1692 57.8797 63.627 59.1174 63.627C60.3551 63.627 61.6219 65.1692 62.0043 67.1424C62.3866 69.1155 61.7062 70.6578 60.4653 70.6578Z"
            fill="#263238"
          />
          <path
            d="M68.1376 70.6578L68.0404 70.1426C68.9573 70.1426 69.4693 68.7688 69.155 67.1424C68.8407 65.5159 67.8007 64.1389 66.887 64.1389C65.9733 64.1389 65.4581 65.5159 65.7724 67.1424H65.2573C64.8782 65.1692 65.5521 63.627 66.7898 63.627C68.0275 63.627 69.2943 65.1692 69.6766 67.1424C70.0589 69.1155 69.3785 70.6578 68.1376 70.6578Z"
            fill="#263238"
          />
          <path
            d="M75.8161 70.6578L75.7189 70.1426C76.6358 70.1426 77.1478 68.7688 76.8335 67.1424C76.5192 65.5159 75.4792 64.1389 74.5655 64.1389C73.6518 64.1389 73.1366 65.5159 73.4509 67.1424H72.9358C72.5567 65.1692 73.2274 63.627 74.4683 63.627C75.7092 63.627 76.9728 65.1692 77.3551 67.1424C77.7374 69.1155 77.0506 70.6578 75.8161 70.6578Z"
            fill="#263238"
          />
          <path
            d="M83.4821 70.6578L83.3849 70.1426C84.3018 70.1426 84.8138 68.7688 84.4995 67.1424C84.1852 65.5159 83.1452 64.1389 82.2315 64.1389C81.3178 64.1389 80.8027 65.5159 81.1169 67.1424H80.6018C80.2227 65.1692 80.8934 63.627 82.1343 63.627C83.3752 63.627 84.6388 65.1692 85.0211 67.1424C85.4035 69.1155 84.7231 70.6578 83.4821 70.6578Z"
            fill="#263238"
          />
          <path
            d="M91.1545 70.6578L91.0573 70.1426C91.9742 70.1426 92.4861 68.7688 92.1719 67.1424C91.8576 65.5159 90.8175 64.1389 89.9039 64.1389C88.9902 64.1389 88.475 65.5159 88.7893 67.1424H88.2741C87.8951 65.1692 88.5657 63.627 89.8067 63.627C91.0476 63.627 92.3112 65.1692 92.6935 67.1424C93.0758 69.1155 92.3922 70.6578 91.1545 70.6578Z"
            fill="#263238"
          />
          <path
            d="M98.82 70.6578L98.7228 70.1426C99.6398 70.1426 100.152 68.7688 99.8374 67.1424C99.5231 65.5159 98.4831 64.1389 97.5694 64.1389C96.6557 64.1389 96.1406 65.5159 96.4548 67.1424H95.9397C95.5606 65.1692 96.2313 63.627 97.4722 63.627C98.7131 63.627 99.9767 65.1692 100.356 67.1424C100.735 69.1155 100.064 70.6578 98.82 70.6578Z"
            fill="#263238"
          />
          <path
            d="M65.672 83.1673H38.4171L36.917 75.3945H64.1719L65.672 83.1673Z"
            fill="#407BFF"
          />
          <path
            d="M77.287 92.8649H69.511L68.0142 85.0889H75.7869L77.287 92.8649Z"
            fill="#407BFF"
          />
          <path
            d="M101.607 102.818L101.186 102.452C100.321 101.695 99.3589 101.057 98.3247 100.556L98.5936 99.7627C99.5569 100.229 100.464 100.804 101.296 101.477C102.731 99.3486 104.61 97.5568 106.804 96.2246L107.384 97.0152C105.15 98.3721 103.259 100.225 101.856 102.429L101.607 102.818Z"
            fill="#407BFF"
          />
          <path
            d="M74.4362 86.375H73.7331L72.557 88.5037L70.5644 86.375H69.8613L72.2978 88.9767L70.8625 91.5752H71.5688L72.7417 89.4498L74.7343 91.5752H75.4406L73.0009 88.9767L74.4362 86.375Z"
            fill="white"
          />
          <path
            d="M88.8441 104.995L90.1401 111.738H83.4009L82.1049 104.995H88.8473M89.2653 104.48H81.4893L82.9894 112.256H90.7654L89.2685 104.48H89.2653Z"
            fill="#407BFF"
          />
          <path
            d="M69.4108 102.559H51.8986L50.3984 94.7861H67.9106L69.4108 102.559Z"
            fill="#263238"
          />
          <path
            d="M57.348 97.8865C57.3947 98.0671 57.3984 98.2561 57.359 98.4384C57.3196 98.6207 57.238 98.7912 57.1209 98.9363C57.0038 99.0815 56.8543 99.1972 56.6845 99.2743C56.5146 99.3513 56.3291 99.3876 56.1428 99.3802C55.722 99.3645 55.3185 99.2092 54.9958 98.9387C54.6731 98.6682 54.4497 98.298 54.3608 97.8865C54.3148 97.706 54.3116 97.5172 54.3513 97.3353C54.391 97.1533 54.4726 96.983 54.5896 96.8381C54.7066 96.6931 54.8557 96.5774 55.0252 96.5001C55.1947 96.4228 55.3799 96.3861 55.566 96.3929C55.9866 96.4091 56.3899 96.5646 56.7124 96.835C57.035 97.1054 57.2586 97.4752 57.348 97.8865Z"
            fill="white"
          />
          <path
            d="M57.7081 99.2993C57.5432 99.5284 57.3247 99.7134 57.0716 99.8383C56.8185 99.9631 56.5386 100.024 56.2566 100.015C55.6134 99.9999 54.9991 99.7458 54.5329 99.3025C54.2444 99.4585 54.0126 99.7017 53.8706 99.9973C53.7286 100.293 53.6837 100.626 53.7423 100.948H59.1337C59.0361 100.584 58.8572 100.246 58.6103 99.9602C58.3633 99.6745 58.0549 99.4486 57.7081 99.2993Z"
            fill="white"
          />
          <path
            d="M66.4916 98.1882H60.53L60.3647 97.3296H66.3263L66.4916 98.1882Z"
            fill="white"
          />
          <path
            d="M64.8488 100.012H60.883L60.7178 99.1533H64.6835L64.8488 100.012Z"
            fill="white"
          />
          <path
            d="M96.1503 76.1205C97.686 76.1205 99.8601 76.3441 101.503 77.4165C101.801 77.5925 102.061 77.8266 102.268 78.1048C102.474 78.3831 102.623 78.6999 102.705 79.0365C102.993 80.3099 102.316 81.6998 101.568 82.4547C100.385 83.6471 98.5446 84.3987 96.3836 84.5478C96.1373 84.5672 95.8846 84.5769 95.6351 84.5769C94.1134 84.5636 92.6077 84.2647 91.1963 83.6957C89.8064 83.1416 88.3322 82.3997 87.9174 80.9546C87.8092 80.6217 87.776 80.2688 87.8202 79.9215C87.8645 79.5742 87.9851 79.241 88.1734 78.9458C88.4182 78.5711 88.7422 78.2548 89.1227 78.0192C90.9179 76.9089 92.9636 76.2682 95.0714 76.1562C95.431 76.1335 95.7939 76.1205 96.1503 76.1205ZM96.0531 75.6216C95.6675 75.6216 95.2949 75.6216 94.945 75.6605C92.764 75.7752 90.647 76.437 88.789 77.585C88.3619 77.8509 87.9981 78.207 87.723 78.6283C87.5033 78.9786 87.363 79.3727 87.3121 79.783C87.2611 80.1934 87.3008 80.6099 87.4282 81.0032C87.9142 82.6815 89.5763 83.5531 91.0862 84.1525C92.562 84.7489 94.1373 85.0611 95.7291 85.0727C95.9915 85.0727 96.2507 85.0727 96.5099 85.0467C98.5641 84.8977 100.612 84.1979 101.96 82.8371C103.307 81.4763 104.166 78.5894 101.72 77.0051C100.071 75.9391 97.9128 75.6216 96.0498 75.6216H96.0531Z"
            fill="#263238"
          />
          <path
            d="M125.395 132.568L127.715 131.952L126.584 126.496L124.264 127.115L125.395 132.568Z"
            fill="#B55B52"
          />
          <path
            d="M127.789 131.525L125.226 132.367C125.182 132.379 125.145 132.408 125.123 132.448C125.1 132.487 125.094 132.534 125.106 132.578L125.65 134.648C125.685 134.755 125.76 134.844 125.859 134.895C125.959 134.947 126.075 134.957 126.182 134.923C127.07 134.599 127.478 134.421 128.605 134.052C129.299 133.822 130.977 133.307 131.933 132.992C132.889 132.678 132.555 131.742 132.121 131.787C130.177 132.001 129.039 131.829 128.233 131.538C128.091 131.483 127.934 131.478 127.789 131.525Z"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M124.264 127.115L126.584 126.496L127.167 129.308L124.844 129.927L124.264 127.115Z"
            fill="black"
          />
          <path
            d="M118.315 71.5137C118.315 71.5137 121.555 91.0671 122.527 97.8873C123.58 105.355 127.523 127.822 127.523 127.822L123.635 129.293C123.635 129.293 117.431 107.206 115.717 99.9447C113.854 92.0618 109.752 71.5137 109.752 71.5137H118.315Z"
            fill="#263238"
          />
          <path
            opacity="0.3"
            d="M113.109 79.5327C114.767 83.6767 114.955 90.7204 114.281 93.6073C113.264 88.8671 112.098 83.1971 111.2 78.7875C111.521 77.6567 112.104 77.012 113.109 79.5327Z"
            fill="black"
          />
          <path
            d="M123.46 129.843L127.974 128.489L127.844 126.642L122.472 128.213L123.46 129.843Z"
            fill="#263238"
          />
          <path
            d="M128.728 131.658C128.517 131.719 128.3 131.759 128.08 131.774C128.07 131.775 128.059 131.773 128.049 131.769C128.04 131.764 128.031 131.757 128.025 131.748C128.02 131.739 128.017 131.728 128.017 131.718C128.017 131.707 128.02 131.696 128.025 131.687C128.08 131.612 128.583 130.958 128.897 130.974C128.932 130.977 128.967 130.988 128.997 131.007C129.027 131.026 129.051 131.053 129.069 131.084C129.104 131.13 129.125 131.185 129.13 131.242C129.135 131.299 129.124 131.357 129.098 131.408C129.008 131.532 128.877 131.621 128.728 131.658ZM128.213 131.658C128.651 131.602 128.946 131.492 129.017 131.353C129.031 131.32 129.036 131.284 129.032 131.248C129.029 131.212 129.015 131.178 128.994 131.149C128.986 131.133 128.974 131.12 128.959 131.11C128.944 131.101 128.927 131.095 128.91 131.094C128.741 131.078 128.424 131.395 128.213 131.651V131.658Z"
            fill="#407BFF"
          />
          <path
            d="M128.107 131.771C128.098 131.774 128.089 131.774 128.081 131.771C128.071 131.769 128.063 131.766 128.055 131.76C128.048 131.754 128.042 131.747 128.038 131.738C128.038 131.709 127.76 131.071 127.915 130.766C127.936 130.728 127.965 130.695 127.999 130.67C128.034 130.644 128.074 130.626 128.116 130.617C128.163 130.604 128.212 130.606 128.257 130.625C128.302 130.643 128.339 130.676 128.362 130.718C128.499 130.948 128.314 131.538 128.132 131.745C128.123 131.764 128.116 131.771 128.107 131.771ZM128.107 130.744C128.065 130.759 128.03 130.789 128.009 130.828C127.955 131.089 127.985 131.36 128.097 131.602C128.255 131.36 128.313 131.067 128.259 130.783C128.243 130.754 128.213 130.721 128.132 130.737L128.107 130.744Z"
            fill="#407BFF"
          />
          <path
            d="M117.823 51.3834C117.917 51.5357 118.047 51.7495 118.166 51.9342L118.536 52.5174C118.792 52.903 119.044 53.2885 119.313 53.6676C119.842 54.4225 120.399 55.1548 120.976 55.8514C121.538 56.5324 122.146 57.1743 122.796 57.7727C123.384 58.3217 124.037 58.7961 124.74 59.1853L124.228 59.0395C124.73 59.1426 125.244 59.172 125.755 59.127C126.033 59.1011 126.318 59.0654 126.61 59.0136C126.901 58.9618 127.196 58.8905 127.494 58.8095C128.108 58.6423 128.711 58.4378 129.299 58.1971C129.6 58.074 129.902 57.9476 130.203 57.8115C130.504 57.6755 130.812 57.5232 131.075 57.4001L131.162 57.358C131.413 57.2377 131.702 57.222 131.965 57.3143C132.228 57.4066 132.444 57.5994 132.565 57.8504C132.656 58.0439 132.686 58.2603 132.652 58.4712C132.618 58.6822 132.521 58.878 132.374 59.033C132.079 59.3376 131.82 59.5806 131.528 59.8365C131.237 60.0925 130.945 60.3193 130.637 60.5461C130.011 61.0037 129.347 61.409 128.654 61.7579C127.923 62.1254 127.152 62.409 126.357 62.6035C125.93 62.7016 125.497 62.772 125.061 62.8141C124.603 62.8529 124.142 62.8529 123.684 62.8141C123.554 62.8013 123.426 62.7696 123.305 62.7202L123.175 62.6651C122.105 62.2016 121.105 61.5897 120.204 60.8474C119.351 60.159 118.556 59.4004 117.829 58.5794C117.119 57.7932 116.455 56.9667 115.84 56.1041C115.536 55.6721 115.242 55.2401 114.959 54.8081C114.82 54.5845 114.68 54.3642 114.544 54.1342C114.408 53.9041 114.285 53.6903 114.136 53.4116C113.86 52.9266 113.788 52.3518 113.936 51.8137C114.084 51.2757 114.44 50.8184 114.925 50.5426C115.41 50.2668 115.985 50.1949 116.523 50.3429C117.061 50.4908 117.518 50.8465 117.794 51.3315L117.823 51.3834Z"
            fill="#263238"
          />
          <path
            opacity="0.3"
            d="M119.819 57.3096C118.555 54.7176 116.401 54.8666 115.532 55.6669L115.778 56.0265C116.367 56.8602 117 57.6606 117.677 58.4241C118.37 59.209 119.119 59.9421 119.919 60.6176C120.172 60.825 120.434 61.0291 120.7 61.2235C120.664 59.8739 120.365 58.5444 119.819 57.3096Z"
            fill="black"
          />
          <path
            d="M131.794 57.0244L133.414 55.6733L134.386 57.7696C134.386 57.7696 131.972 59.4965 131.418 58.8874L131.353 58.1066C131.335 57.9041 131.365 57.7002 131.442 57.512C131.519 57.3237 131.639 57.1565 131.794 57.0244Z"
            fill="#B55B52"
          />
          <path
            d="M135.575 55.9293L136.083 57.5493L134.373 57.7599L133.407 55.6636L135.575 55.9293Z"
            fill="#B55B52"
          />
          <path
            d="M105.099 132.49H107.5L107.811 126.927H105.41L105.099 132.49Z"
            fill="#B55B52"
          />
          <path
            d="M107.627 132.212H104.928C104.905 132.211 104.883 132.214 104.862 132.222C104.84 132.23 104.821 132.242 104.804 132.257C104.788 132.273 104.774 132.291 104.765 132.312C104.756 132.332 104.75 132.354 104.75 132.377L104.617 134.512C104.617 134.624 104.661 134.732 104.74 134.812C104.818 134.892 104.926 134.938 105.038 134.94C105.977 134.923 106.428 134.868 107.614 134.868C108.343 134.868 110.355 134.943 111.362 134.943C112.37 134.943 112.334 133.945 111.916 133.854C109.998 133.452 108.719 132.882 108.028 132.361C107.915 132.267 107.774 132.214 107.627 132.212Z"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M105.411 126.931H107.811L107.649 129.798H105.249L105.411 126.931Z"
            fill="black"
          />
          <path
            d="M113.624 71.5137C113.624 71.5137 113.417 92.3825 113.028 99.261C112.6 106.791 108.68 129.105 108.68 129.105H104.28C104.28 129.105 106.198 107.397 105.932 99.9447C105.644 91.8447 105.469 71.5137 105.469 71.5137H113.624Z"
            fill="#263238"
          />
          <path
            d="M103.927 129.105H109.292L109.554 127.436L103.7 127.695L103.927 129.105Z"
            fill="#263238"
          />
          <path
            d="M108.475 132.63C108.257 132.625 108.04 132.596 107.827 132.545C107.817 132.543 107.807 132.538 107.799 132.53C107.791 132.522 107.786 132.513 107.784 132.502C107.782 132.491 107.783 132.48 107.787 132.47C107.791 132.459 107.799 132.451 107.808 132.445C107.886 132.39 108.563 131.927 108.858 132.043C108.891 132.056 108.92 132.077 108.943 132.104C108.966 132.131 108.981 132.164 108.987 132.199C109.003 132.251 109.004 132.306 108.992 132.36C108.981 132.413 108.955 132.462 108.919 132.503C108.792 132.598 108.634 132.643 108.475 132.63ZM107.989 132.464C108.42 132.549 108.738 132.536 108.848 132.425C108.872 132.399 108.889 132.366 108.897 132.331C108.904 132.295 108.902 132.259 108.89 132.225C108.888 132.207 108.88 132.19 108.869 132.177C108.857 132.163 108.842 132.153 108.825 132.147C108.67 132.085 108.268 132.286 107.989 132.464Z"
            fill="#407BFF"
          />
          <path
            d="M107.85 132.545C107.842 132.549 107.833 132.549 107.824 132.545C107.816 132.541 107.809 132.535 107.803 132.527C107.798 132.519 107.795 132.51 107.795 132.5C107.795 132.471 107.737 131.781 107.98 131.547C108.011 131.518 108.048 131.495 108.089 131.482C108.13 131.468 108.174 131.464 108.216 131.47C108.264 131.471 108.31 131.489 108.347 131.519C108.384 131.55 108.41 131.591 108.421 131.638C108.476 131.901 108.116 132.403 107.879 132.545C107.87 132.548 107.86 132.548 107.85 132.545ZM108.174 131.573C108.13 131.573 108.086 131.591 108.054 131.622C107.921 131.852 107.865 132.119 107.896 132.383C108.106 132.208 108.343 131.826 108.307 131.654C108.307 131.622 108.281 131.583 108.2 131.573H108.174Z"
            fill="#407BFF"
          />
          <path
            d="M117.304 51.9988C117.183 51.5936 116.955 51.229 116.642 50.9436C116.33 50.6583 115.947 50.4631 115.532 50.3788C114.94 50.2387 114.339 50.1359 113.734 50.071C111.916 49.9672 110.093 49.9672 108.275 50.071C107.322 50.1163 106.35 50.233 105.576 50.3464H105.547C105.343 50.375 105.147 50.4443 104.971 50.5502C104.795 50.6561 104.642 50.7963 104.521 50.9626C104.4 51.1288 104.313 51.3177 104.267 51.518C104.221 51.7183 104.215 51.9259 104.251 52.1284C104.861 55.3508 105.26 58.6096 105.446 61.884C105.634 66.6371 105.378 70.3696 105.469 71.5165H118.315C119.096 59.9141 118.089 54.6426 117.304 51.9988Z"
            fill="#E6E6E6"
          />
          <path
            d="M108.637 49.8574C109.648 53.9593 110.581 64.3532 110.565 72.4791H105.135C105.038 71.2673 105.31 67.334 105.112 62.3217C104.918 58.8759 104.501 55.4463 103.865 52.0541C103.828 51.8416 103.834 51.6237 103.882 51.4134C103.93 51.2032 104.02 51.0047 104.147 50.8296C104.273 50.6546 104.433 50.5065 104.617 50.3941C104.801 50.2816 105.006 50.2071 105.219 50.1749H105.248C106.065 50.0551 107.089 49.9319 108.093 49.8833L108.637 49.8574Z"
            fill="#263238"
          />
          <path
            d="M117.965 52.0574C117.827 51.6095 117.575 51.2051 117.235 50.8832C116.894 50.5613 116.476 50.3329 116.021 50.2203C115.163 50.0853 114.297 49.9966 113.429 49.9546C114.456 53.5186 116.472 64.3758 115.872 72.4791H119.3C120.113 60.3194 118.73 54.28 117.965 52.0574Z"
            fill="#263238"
          />
          <path
            d="M137.989 63.2904L112.428 74.2286L109.357 67.0877L134.921 56.1494L137.989 63.2904Z"
            fill="#407BFF"
          />
          <path
            d="M116.417 67.4409C116.533 67.7123 116.566 68.0122 116.512 68.3025C116.458 68.5928 116.319 68.8605 116.113 69.0719C115.906 69.2832 115.642 69.4287 115.353 69.49C115.064 69.5512 114.764 69.5254 114.49 69.4159C114.215 69.3064 113.98 69.1181 113.812 68.8747C113.645 68.6313 113.554 68.3438 113.55 68.0485C113.546 67.7532 113.63 67.4634 113.79 67.2157C113.951 66.968 114.182 66.7735 114.453 66.6568C114.633 66.5792 114.827 66.5379 115.024 66.5353C115.22 66.5327 115.415 66.5688 115.597 66.6417C115.78 66.7145 115.946 66.8226 116.087 66.9597C116.227 67.0968 116.339 67.2603 116.417 67.4409Z"
            fill="white"
          />
          <path
            d="M117.061 68.7046C116.966 68.9887 116.812 69.2495 116.609 69.4699C116.406 69.6903 116.159 69.8652 115.884 69.9833C115.608 70.1014 115.311 70.16 115.011 70.1551C114.712 70.1503 114.417 70.0822 114.145 69.9552C113.915 70.2165 113.76 70.5348 113.695 70.8767C113.63 71.2187 113.658 71.5718 113.776 71.8992L118.733 69.7803C118.577 69.4671 118.339 69.2019 118.045 69.0126C117.751 68.8232 117.411 68.7168 117.061 68.7046Z"
            fill="white"
          />
          <path
            d="M134.353 60.0957L119.414 66.4883L119.077 65.6977L134.016 59.3052L134.353 60.0957Z"
            fill="white"
          />
          <path
            d="M133.242 62.5547L120.136 68.1632L119.796 67.3726L132.902 61.7642L133.242 62.5547Z"
            fill="white"
          />
          <path
            opacity="0.3"
            d="M106.813 56.2144C106.723 53.4896 104.711 52.4495 104.144 53.1818C104.119 53.2114 104.096 53.2428 104.076 53.2757C104.429 55.3753 104.86 58.2653 105.048 61.1133C106.003 60.5625 106.898 58.8032 106.813 56.2144Z"
            fill="black"
          />
          <path
            d="M113.475 50.0646C111.887 49.6888 111.609 48.7071 111.716 47.6281C111.741 47.3579 111.783 47.0895 111.842 46.8246L109.853 45.1722L108.391 43.9604C108.796 45.7198 109.253 48.9241 108.067 50.097C108.067 50.097 109.039 51.717 111.771 51.5744C114.048 51.4481 113.475 50.0646 113.475 50.0646Z"
            fill="#B55B52"
          />
          <path
            d="M110.678 53.023C110.866 52.4831 111.172 51.9917 111.573 51.5844C110.353 51.1843 109.244 50.5054 108.333 49.6016L107.5 49.9256C107.5 49.9256 107.795 52.0413 110.678 53.023Z"
            fill="white"
          />
          <path
            d="M112.901 52.6601C112.541 52.2105 112.088 51.8436 111.573 51.5844C112.373 51.3576 112.684 49.7344 112.684 49.7344L113.465 49.8964C113.465 49.8964 114.907 52.0121 112.901 52.6601Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M111.842 46.8248C111.783 47.0897 111.74 47.3581 111.715 47.6283C110.973 47.4792 109.982 46.6887 109.878 45.9305C109.842 45.6795 109.833 45.4253 109.852 45.1724L111.842 46.8248Z"
            fill="black"
          />
          <path
            d="M112.768 38.1837C113.77 38.0022 114.936 39.052 114.388 41.1839C113.841 43.3158 111.239 38.4591 112.768 38.1837Z"
            fill="#263238"
          />
          <path
            d="M107.789 41.4301C108.297 43.7662 108.475 45.1561 109.856 46.2221C110.3 46.573 110.833 46.7956 111.395 46.8659C111.957 46.9362 112.527 46.8515 113.045 46.621C113.562 46.3904 114.007 46.0229 114.33 45.558C114.654 45.0931 114.844 44.5485 114.881 43.9833C115.062 41.8092 114.204 38.3554 111.745 37.6944C111.216 37.537 110.656 37.5212 110.12 37.6488C109.583 37.7764 109.09 38.0428 108.689 38.4213C108.288 38.7998 107.994 39.277 107.836 39.8051C107.678 40.3333 107.662 40.8937 107.789 41.4301Z"
            fill="#B55B52"
          />
          <path
            d="M108.728 43.9605C109.072 43.1505 109.376 40.8955 109.097 39.9591C110.215 39.9235 112.564 39.7161 113.543 38.6275C115.046 36.9557 113.063 34.9793 110.568 36.5539C108.948 37.568 105.453 37.3671 106.032 38.4979C104.328 40.3739 106.243 42.4928 108.728 43.9605Z"
            fill="#263238"
          />
          <path
            d="M106.029 38.5821C106.017 38.582 106.006 38.5794 105.995 38.5743C105.984 38.5693 105.975 38.562 105.968 38.553C105.76 38.297 105.695 38.0832 105.767 37.905C105.938 37.4773 106.829 37.3607 107.856 37.2311C108.828 37.1079 109.904 36.9686 110.248 36.5312C110.263 36.5171 110.283 36.5093 110.304 36.5093C110.325 36.5093 110.345 36.5171 110.361 36.5312C110.369 36.5385 110.375 36.5473 110.379 36.557C110.383 36.5668 110.386 36.5773 110.386 36.5879C110.386 36.5985 110.383 36.6091 110.379 36.6188C110.375 36.6286 110.369 36.6373 110.361 36.6446C109.975 37.1339 108.903 37.2699 107.866 37.4028C106.969 37.5194 106.042 37.6361 105.922 37.9795C105.874 38.1026 105.922 38.2679 106.094 38.472C106.109 38.4871 106.117 38.5075 106.117 38.5287C106.117 38.5499 106.109 38.5702 106.094 38.5854C106.073 38.5917 106.05 38.5906 106.029 38.5821Z"
            fill="#263238"
          />
          <path
            d="M107.429 43.3156C107.708 43.7518 108.129 44.0781 108.621 44.239C109.247 44.4269 109.46 43.8502 109.208 43.2411C108.978 42.6903 108.375 41.9289 107.789 42.0099C107.202 42.0909 107.076 42.768 107.429 43.3156Z"
            fill="#B55B52"
          />
          <path
            d="M111.132 41.6636C111.161 41.8548 111.288 41.9876 111.414 41.9876C111.541 41.9876 111.615 41.8062 111.586 41.615C111.557 41.4239 111.43 41.291 111.304 41.291C111.178 41.291 111.103 41.4725 111.132 41.6636Z"
            fill="#263238"
          />
          <path
            d="M113.306 41.4042C113.335 41.5953 113.462 41.7282 113.588 41.7282C113.714 41.7282 113.792 41.5435 113.76 41.3523C113.727 41.1612 113.604 41.0283 113.481 41.0283C113.358 41.0283 113.277 41.213 113.306 41.4042Z"
            fill="#263238"
          />
          <path
            d="M112.973 41.5825C113.294 42.142 113.689 42.6563 114.146 43.1118C114.026 43.2211 113.884 43.3027 113.73 43.3509C113.575 43.3991 113.412 43.4126 113.251 43.3904L112.973 41.5825Z"
            fill="#A02724"
          />
          <path
            d="M112.545 44.0026C112.32 44.0056 112.097 43.963 111.889 43.8772C111.682 43.7914 111.494 43.6644 111.336 43.5037C111.331 43.4981 111.328 43.4917 111.325 43.4847C111.323 43.4777 111.322 43.4703 111.322 43.463C111.323 43.4556 111.325 43.4484 111.328 43.4417C111.331 43.4351 111.336 43.4292 111.341 43.4243C111.347 43.4194 111.353 43.4156 111.36 43.4132C111.367 43.4108 111.375 43.4098 111.382 43.4103C111.389 43.4107 111.397 43.4126 111.403 43.4158C111.41 43.4191 111.416 43.4236 111.421 43.4291C111.595 43.5993 111.806 43.7278 112.037 43.8053C112.268 43.8827 112.514 43.9069 112.756 43.8762C112.763 43.8749 112.771 43.875 112.778 43.8768C112.785 43.8785 112.792 43.8818 112.799 43.8863C112.805 43.8909 112.81 43.8967 112.813 43.9033C112.817 43.9099 112.82 43.9173 112.82 43.9249C112.821 43.9321 112.821 43.9394 112.819 43.9465C112.817 43.9535 112.813 43.9601 112.809 43.9658C112.804 43.9715 112.799 43.9763 112.792 43.9798C112.786 43.9834 112.779 43.9856 112.772 43.9864C112.696 43.9964 112.621 44.0018 112.545 44.0026Z"
            fill="#263238"
          />
          <path
            d="M110.423 41.0447C110.403 41.0434 110.384 41.0379 110.367 41.0285C110.354 41.0211 110.343 41.0112 110.334 40.9994C110.325 40.9875 110.318 40.974 110.314 40.9595C110.31 40.9451 110.309 40.93 110.311 40.9151C110.313 40.9003 110.318 40.886 110.325 40.873C110.409 40.7151 110.53 40.5802 110.678 40.4802C110.826 40.3801 110.997 40.3181 111.174 40.2995C111.189 40.2986 111.204 40.3007 111.218 40.3056C111.232 40.3105 111.245 40.3182 111.256 40.3281C111.268 40.338 111.277 40.3501 111.283 40.3635C111.29 40.3769 111.293 40.3915 111.294 40.4064C111.295 40.4213 111.293 40.4363 111.288 40.4503C111.283 40.4644 111.275 40.4774 111.266 40.4886C111.256 40.4997 111.244 40.5088 111.23 40.5152C111.217 40.5217 111.202 40.5255 111.187 40.5263C111.047 40.5429 110.914 40.5935 110.798 40.6737C110.682 40.7539 110.588 40.8613 110.523 40.9864C110.513 41.0043 110.499 41.0191 110.481 41.0294C110.463 41.0397 110.443 41.0449 110.423 41.0447Z"
            fill="#263238"
          />
          <path
            d="M114.282 40.5487C114.263 40.5488 114.244 40.5441 114.228 40.535C114.211 40.526 114.198 40.5128 114.188 40.4969C114.109 40.3744 114.003 40.2715 113.878 40.1962C113.754 40.121 113.613 40.0753 113.468 40.0627C113.438 40.0627 113.409 40.0508 113.388 40.0295C113.367 40.0083 113.355 39.9794 113.355 39.9493C113.355 39.9193 113.367 39.8904 113.388 39.8692C113.409 39.8479 113.438 39.8359 113.468 39.8359C113.651 39.8487 113.828 39.9036 113.985 39.9964C114.142 40.0891 114.276 40.2172 114.376 40.3705C114.392 40.396 114.397 40.4268 114.391 40.4565C114.385 40.4861 114.368 40.5122 114.343 40.5293C114.325 40.5416 114.304 40.5484 114.282 40.5487Z"
            fill="#263238"
          />
          <path
            d="M107.108 53.7032L107.085 53.7388L107.03 53.8295L106.914 54.0337C106.833 54.1795 106.755 54.3285 106.677 54.484C106.528 54.808 106.382 55.132 106.253 55.456C105.994 56.1346 105.788 56.8322 105.637 57.5426C105.336 59.0095 105.248 60.512 105.375 62.0041C105.392 62.3179 105.435 62.6299 105.501 62.9372C105.575 63.2475 105.678 63.5502 105.809 63.8411C106.109 64.5057 106.477 65.1376 106.907 65.7268C107.853 67.0063 108.912 68.1991 110.07 69.2908L110.115 69.3362C110.295 69.5113 110.407 69.7452 110.429 69.9955C110.451 70.2458 110.383 70.4959 110.237 70.7002C110.091 70.9045 109.876 71.0496 109.632 71.109C109.388 71.1685 109.13 71.1384 108.906 71.0242C108.094 70.6089 107.316 70.1289 106.58 69.5889C105.832 69.0483 105.126 68.4503 104.471 67.8004C103.775 67.1086 103.166 66.3351 102.656 65.4968C102.377 65.0406 102.143 64.5576 101.96 64.055C101.781 63.5466 101.65 63.0227 101.568 62.4901C101.4 61.5521 101.313 60.6014 101.309 59.6486C101.294 58.6839 101.366 57.7198 101.526 56.7682C101.686 55.8023 101.939 54.854 102.281 53.9365C102.455 53.4688 102.655 53.0113 102.88 52.5659C102.997 52.3391 103.116 52.1156 103.253 51.892C103.321 51.7786 103.392 51.6652 103.466 51.5486L103.586 51.3736L103.748 51.1501C104.091 50.7233 104.587 50.4468 105.13 50.3793C105.674 50.3119 106.222 50.4586 106.659 50.7886C107.096 51.1185 107.387 51.6057 107.471 52.1467C107.555 52.6878 107.425 53.2403 107.108 53.687V53.7032Z"
            fill="#263238"
          />
          <path
            d="M110.757 69.3169L112.095 69.2456L110.543 71.8376C110.543 71.8376 108.378 71.5622 108.599 70.4574L108.9 70.1334C109.393 69.6355 110.057 69.3436 110.757 69.3169Z"
            fill="#B55B52"
          />
          <path
            d="M113.481 70.1755L112.315 72.9295L110.542 71.8473L112.094 69.2456L113.481 70.1755Z"
            fill="#B55B52"
          />
        </svg>
      </div>
      <div className="flex-col justify-space-between align-center gap-20">
        <div className="f-s-16 manrope-bold">Explore Events</div>
        <div>
          <CustomButton
            onClick={() => navigate("/events")}
            className={"f-s-14 primary-btn"}
          >
            View All
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
