import { combineReducers } from 'redux';
/* PLOP_INJECT_REDUCER_IMPORT */
import { SearchReducer } from '../app/search';
import { EventsReducer } from '../app/events';
import { ProfileReducer } from '../app/profile';
import { InviteReducer } from '../app/invite';
import { NetworkingReducer } from '../app/networking';
import { OnboardingReducer } from '../app/onboarding';
import { RegisterReducer } from '../app/register';
import { HomeReducer } from '../app/home';


export default combineReducers({
    /* PLOP_INJECT_REDUCER */
	SearchState: SearchReducer,
	EventsState: EventsReducer,
	ProfileState: ProfileReducer,
	InviteState: InviteReducer,
	NetworkingState: NetworkingReducer,
	OnboardingState: OnboardingReducer,
	RegisterState: RegisterReducer,
    HomeState: HomeReducer,
});