import React, { useEffect } from 'react';
import { io } from 'socket.io-client';
import { getToken } from './ManageToken';
import { useDispatch, useSelector } from 'react-redux';
import { connectWebSocket, receiveWebSocketMessage, webSocketError } from '../app/home/HomeAction';
import { API_URL } from './Constant';

const WebSocketNotifications = () => {
    const { websocket, refresh } = useSelector(state => state.HomeState.notifications);
    const { token: login } = useSelector(state => state.OnboardingState);
    const dispatch = useDispatch()


    useEffect(() => {
        let socket;
        const token = getToken();
        if (token && !websocket) {
            socket = io(API_URL?.replace("/ipmmi", ""), {
                extraHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
            socket.on('receiveNotification', (notification) => {
                dispatch(receiveWebSocketMessage(notification))
            });
            socket.on('connect', () => {
                dispatch(connectWebSocket())
            });

            socket.on('connect_error', (error) => {
                dispatch(webSocketError(error))
            });
        }


        return () => {
            if (socket) {
                socket.disconnect()
            }
        }
    }, [websocket, dispatch, login, refresh]);

    return (
        <>
        </>
    );
}

export default WebSocketNotifications;
