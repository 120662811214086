import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import postLoginInstance from "./PostLoginAxios";
import { useSelector } from "react-redux";
import { getToken } from "./ManageToken";

const AddToHomeScreen = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isAppInstalled, setIsAppInstalled] = useState(false);
    const [serviceWorker, setServiceWorker] = useState(null);

    function subscribeUserToPush() {
        navigator.serviceWorker.ready.then(function (registration) {
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: (process.env.REACT_APP_PUBLIC_VAPID_KEY)
            }).then(function (subscription) {
                postLoginInstance.patch("/notifications/subscribe", { subscription }).then(response => {
                }).catch(error => {
                });
            }).catch(function (error) {
                console.error('Failed to subscribe user:', error);
            });

        });
    }
    const { token } = useSelector((state) => state.OnboardingState);
    useEffect(() => {
        if (token) {
            if ('Notification' in window) {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        if (token)
                            subscribeUserToPush();
                    } else {
                    }
                });
            }
        }
    }, [token])


    useEffect(() => {
        const registerServiceWorker = async () => {
            if ("serviceWorker" in navigator) {
                try {
                    const registration = await navigator.serviceWorker.register("/sw.js");
                    setServiceWorker(registration);
                    if (getToken())
                        if ('Notification' in window) {
                            // Request notification permission
                            Notification.requestPermission().then(permission => {
                                if (permission === 'granted') {
                                    subscribeUserToPush();
                                } else {
                                }
                            });
                        }
                    // Check if app is installed
                    const isInstalled = window.matchMedia('(display-mode: standalone)').matches;
                    setIsAppInstalled(isInstalled);
                } catch (error) {
                    console.error("ServiceWorker registration failed:", error);
                }
            }
        };

        registerServiceWorker();
    }, []);

    useEffect(() => {
        if (!serviceWorker) return

        // Handle beforeinstallprompt event
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e); // Save the event to trigger later
        };

        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        window.addEventListener("appinstalled", () => {
            setIsAppInstalled(true);
        });

        return () => {
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        };
    }, [serviceWorker]);

    const handleAddToHomeScreen = () => {
        if (!deferredPrompt) {
            return;
        }

        // Show the prompt
        deferredPrompt.prompt();

        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
            } else {
            }
            setDeferredPrompt(null); // Reset the deferred prompt after user action
        });
    };

    return (
        <div>
            {isAppInstalled ? (
                <p></p>
            ) : (
                deferredPrompt ? (
                    <div className="A2HS-container flex-row gap-12 align-center justify-space-between">
                        <div className="flex-row gap-10 align-center">
                            <img src="/favicon.svg" width={"50"} height={"50"} alt="App logo" />
                            <div className="blue-0E1 manrope-semibold pointer" onClick={handleAddToHomeScreen}
                            >
                                Add IPMMI to Home Screen
                            </div>
                        </div>

                        <div onClick={() => setDeferredPrompt(false)} className="pe-3 pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.47032 5.46934C5.61094 5.32889 5.80157 5.25 6.00032 5.25C6.19907 5.25 6.38969 5.32889 6.53032 5.46934L18.5303 17.4693C18.604 17.538 18.6631 17.6208 18.7041 17.7128C18.7451 17.8048 18.7671 17.9041 18.7689 18.0048C18.7707 18.1055 18.7522 18.2055 18.7144 18.2989C18.6767 18.3923 18.6206 18.4772 18.5494 18.5484C18.4781 18.6196 18.3933 18.6757 18.2999 18.7135C18.2065 18.7512 18.1065 18.7697 18.0058 18.7679C17.9051 18.7662 17.8058 18.7441 17.7138 18.7031C17.6218 18.6621 17.539 18.603 17.4703 18.5293L5.47032 6.52934C5.32987 6.38871 5.25098 6.19809 5.25098 5.99934C5.25098 5.80059 5.32987 5.60997 5.47032 5.46934Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5298 5.46934C18.6703 5.60997 18.7492 5.80059 18.7492 5.99934C18.7492 6.19809 18.6703 6.38871 18.5298 6.52934L6.52985 18.5293C6.38767 18.6618 6.19963 18.7339 6.00532 18.7305C5.81102 18.7271 5.62564 18.6484 5.48822 18.511C5.35081 18.3735 5.2721 18.1882 5.26867 17.9939C5.26524 17.7996 5.33737 17.6115 5.46985 17.4693L17.4698 5.46934C17.6105 5.32889 17.8011 5.25 17.9998 5.25C18.1986 5.25 18.3892 5.32889 18.5298 5.46934Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                ) : ""
            )}
        </div >
    );
};

export default AddToHomeScreen;
