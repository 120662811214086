import React from "react";

const LeftScreen = () => {
    return (
        <div className="left-screen-main">
            <h2 className="f-s-40 manrope-extrabold white">
                Join the Future of Packaging: Connect, Collaborate, Grow.
            </h2>
            <p className="f-s-20 manrope-regular white">
                Meet fellow industry stalwarts. Network to multiply business success. Maximise visibility. Stay updated with IPMMI.
            </p>
        </div>
    );
};

export default LeftScreen;
