import React, { useState } from "react";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useNavigate } from "react-router-dom";
import { getDefaultImgUrl, modals } from "../../../utils/Constant";
import { showModal } from "../../home/HomeAction";
import BasicModal from "../../../utils/commonComponents/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import BookMeetingModal from "../../../utils/commonComponents/modals/BookMeeting";

const PeopleCard = ({ uuid, name, img, designation, meet, userUuid }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState({ uuid: userUuid, name });

  const handleBookMeeting = () => {
    setCurrent({ uuid: userUuid, name });
    setModal(true);
  };

  return (
    <>
      {modal && (
        <BasicModal
          open={modal}
          handleClose={() => setModal(false)}
          closeOnBackdropClick
          className={"edit-member-modal"}
          hideCloseButton={isMobile}
        >
          <BookMeetingModal
            data={{
              uuid: current.uuid,
              user: {
                firstName: current.name.split(" ")[0],
                lastName: current.name.split(" ")[1] || "",
              },
            }}
          />
        </BasicModal>
      )}
      <div className="people-card-main people-card-mobile flex-row justify-space-between align-center round-10">
        <div className="flex-row gap-3 align-center">
          <div className="people-imgae-container">
            <img src={img || getDefaultImgUrl(name)} alt="profile" />
          </div>
          <div className="flex-col">
            <h6 className="f-s-14 manrope-medium">{name}</h6>
            <p className="f-s-12 manrope-regular">{designation || ""}</p>
          </div>
        </div>
        <div className="flex-row gap-3 people-button-group">
          <CustomButton
            className={"primary-btn"}
            onClick={() => navigate(`/profile/${uuid}`)}
          >
            View Profile
          </CustomButton>
          {meet && (
            <CustomButton
              className={"primary-btn bg-grey-F2F black book-meeting-btn"}
              onClick={handleBookMeeting}
            >
              Book a Meeting
            </CustomButton>
          )}
        </div>
      </div>
    </>
  );
};

export default PeopleCard;
