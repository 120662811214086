import { toast } from "react-toastify"
import postLoginInstance from "../../utils/PostLoginAxios"
import { CLEAR_ERRORS, SEND_FOR_REGESTRATION_FAILURE, SEND_FOR_REGESTRATION_REQUEST, SEND_FOR_REGESTRATION_SUCCESS } from "./ActionTypes"




export const fetchOptionsforSelect = async () => {
    try {
        let selectOptions = {
            tags: [],
            companyType: [],
            companyIndustry: [],
            participate: [],
        }
        const tags = await postLoginInstance.get("/tags")
        const companyType = await postLoginInstance.get("/company-category/company-type")
        const companyIndustry = await postLoginInstance.get("/primary-category")
        const participate = await postLoginInstance.get("/company-category/participation-activities")
        selectOptions.tags = tags.data
        selectOptions.companyType = companyType.data
        selectOptions.companyIndustry = companyIndustry.data
        selectOptions.participate = participate.data
        return selectOptions
    }
    catch (error) {
        toast.error(error.response.data.message || "Something went wrong!")
    }
}


export const sendRegisteraionRequest = ({ formData }) => async (dispatch) => {
    try {
        dispatch({ type: SEND_FOR_REGESTRATION_REQUEST })
        await postLoginInstance.post("/company/send-request", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
        dispatch({ type: SEND_FOR_REGESTRATION_SUCCESS })
        toast.success("Request sent successfully!")
    }
    catch (error) {
        dispatch({ type: SEND_FOR_REGESTRATION_FAILURE })
        toast.error(error.response.data.message || "Something went wrong!")
        dispatch({ type: CLEAR_ERRORS })
    }
}
