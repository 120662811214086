export const SET_MODAL = "SET_MODAL";

export const GET_HOME_BANNERS = "GET_HOME_BANNERS";
export const GET_HOME_EVENTS = "GET_HOME_EVENTS";

export const GET_USER_PROFILE = "GET_USER_PROFILE";

export const GET_MEMBER_RECOMMENDATIONS = "GET_MEMBER_RECOMMENDATIONS";
export const GET_COMPANY_RECOMMENDATIONS = "GET_COMPANY_RECOMMENDATIONS";
export const REFRESH_WEB_SOCKET = "REFRESH_WEB_SOCKET";



export const CONNECT_WEBSOCKET = "CONNECT_WEBSOCKET";
export const RECEIVE_WEBSOCKET_MESSAGE = "RECEIVE_WEBSOCKET_MESSAGE";
export const WEBSOCKET_ERROR = "WEBSOCKET_ERROR";
export const UPDATE_NOTIFICATION_LIST = "UPDATE_NOTIFICATION_LIST";
export const DISCONNECT_WEBSOCKET = "DISCONNECT_WEBSOCKET";

export const GET_PAST_NOTIFICATIONS = "GET_PAST_NOTIFICATIONS";

