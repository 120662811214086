import { toast } from "react-toastify";
import { GET_COMPANIES_FAILURE, GET_COMPANIES_REQUEST, GET_COMPANIES_SUCCESS, GET_MEMBERS_FAILURE, GET_MEMBERS_REQUEST, GET_MEMBERS_SUCCESS } from "./ActionTypes";
import postLoginInstance from "../../utils/PostLoginAxios";

export const getMembers = () => async (dispatch) => {
    dispatch({ type: GET_MEMBERS_REQUEST });
    try {
        const { data } = await postLoginInstance("/members/networking-members");
        dispatch({ type: GET_MEMBERS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_MEMBERS_FAILURE, payload: error });
        toast.error("Error while fetching members");
    }
}
export const getCompanies = () => async (dispatch) => {
    dispatch({ type: GET_COMPANIES_REQUEST });
    try {
        const { data } = await postLoginInstance("/company/networking-compaines");
        dispatch({ type: GET_COMPANIES_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_COMPANIES_FAILURE, payload: error });
        toast.error("Error while fetching companies");
    }
}

