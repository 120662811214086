import { West } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import CustomInput from "../forms/CustomInput";
import { showModal } from "../../../app/home/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../Modal";
import CustomButton from "../forms/CustomButton";
import SentImg from "../../../assets/images/request-sent.svg";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { bookMeeting } from "../../../app/profile/ProfileAction";
import { CLEAR_ERRORS } from "../../../app/profile/ActionTypes";

const BookMeetingModal = ({ data }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("");
  const [date, setDate] = React.useState(dayjs());
  const [sent, setSent] = React.useState(false);
  useEffect(() => {
    if (!data) {
      navigate(-1);
    }
  }, [dispatch, data]);


  const sendRequest = () => {

    if (!message || message.length < 5 || message.length > 150) {
      return toast.info("Message should be between 5 to 150 characters");
    }
    if (!date) {
      return toast.info("Please select a date and time");
    }
    dispatch(bookMeeting({ receiverUuid: data.uuid, meetingAgenda: message, date: date.toISOString() }));

  }

  const { meeting, error } = useSelector((state) => state.ProfileState);

  useEffect(() => {
    if (meeting.sent) {
      setSent(true);
    }
    if (error) {
      toast.error("something went wrong");
      dispatch({ type: CLEAR_ERRORS });
      dispatch(showModal(null));
    }
  }, [meeting.sent, dispatch, error]);

  const name = data?.user?.firstName ? `${data.user.firstName} ${data.user.lastName}` : data?.user?.contactPerson;
  return (
    <>
      {sent && (
        <BasicModal
          open={sent}
          handleClose={() => {
            dispatch(showModal(null));
            setSent(false);
          }}
          className={"edit-member-modal"}
          closeOnBackdropClick
        >
          <RequestSent />
        </BasicModal>
      )}
      <div className="book-meeting-modal">
        <div className="flex-row gap-12 align-middle header">
          {isMobile && (
            <div className="flex-row align-center" onClick={() => dispatch(showModal(null))}>
              <West sx={{ fontSize: "20px" }} />
            </div>
          )}
          <div className="text-center">
            <div className="f-s-24 manrope-semibold heading">
              Book a Meeting 
            </div>
            {!isMobile && (
              <p className="f-s-14 manrope-regular">
                Request a meeting with {name || "message"}
              </p>
            )}
          </div>
        </div>

        <div className="input-container flex-col gap-12 mt-4">
          <CustomInput
            heading={"Subject"}
            type={"text-area"}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={message > 150 && "Message should be less than 150 characters"}
            placeholder="What will you like to discuss in the meeting?(In 100 to 150 Words)"
          />
          <CustomInput
            type={"date-time"}
            value={date}
            onChange={(e) => setDate(e)}
            heading={"Date and Time"}
            placeholder={"Select Date and Time"}
          />
        </div>

        <div className="actions">
          <CustomButton
            onClick={sendRequest}
            className="submit-btn primary-btn my-4"
            disabled={meeting.loading}
          >
            Send
          </CustomButton>
        </div>
      </div>
    </>
  );
};

export default BookMeetingModal;

const RequestSent = () => {
  return (
    <div className="request-sent flex-col justify-center align-center">
      <img src={SentImg} alt="" />
      <div className="f-s-24 manrope-semibold text-center">Request Sent</div>
      <div className="f-s-14 manrope-regular text-center request-sent-text pb-4 m-2">
        Your request has been sent to the member. They will review it and may
        choose to accept, reject, or request a reschedule according to their
        availability. You'll be notified once they respond{" "}
      </div>
    </div>
  );
};
