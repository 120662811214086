import React, { useRef } from "react";
import MultiSelect, { SingleSelect } from "./MultipleSelect";
import DateTimeInput from "../date/DateTimeInput";
import DateInput from "../date/DatePicker2";
import CustomButton from "./CustomButton";
import Tooltip from "../common/Tooltip";
import IIcon from "../../../assets/images/Iicon.svg";

const Input = ({
  heading,
  placeholder,
  onChange,
  important = true,
  value,
  type,
  options,
  rows,
  cols,
  readOnly,
  multiple,
  accept,
  maxLength,
  error,
  info,
  tip,
  round,
}) => {
  const inputClass = readOnly ? "input-read" : "input";
  const fileInputRef = useRef(null);

  if (type === "date-time") {
    return (
      <div>
        <div className="flex-row">
          <p className="manrope-regular f-s-14 mb-0 p-1">
            {heading && `${heading}`}
          </p>
          <span>{important && <p className="red-D52">*</p>}</span>
        </div>
        <DateTimeInput value={value} onChange={onChange} />
        {error && <p className="error">{error}</p>}
      </div>
    );
  }
  if (type === "date") {
    return (
      <div>
        <div className="flex-row">
          <p className="manrope-regular f-s-14 mb-0 p-1">
            {heading && `${heading}`}
          </p>
          <span>{important && <p className="red-D52">*</p>}</span>
        </div>
        <DateInput value={value} onChange={onChange} round={round} />
        {error && <p className="error">{error}</p>}
      </div>
    );
  }

  if (type === "number") {
    return (
      <div className="">
        <div className="flex-row">
          <p className="manrope-regular f-s-14 mb-0 p-1">
            {heading && `${heading}`}
          </p>
          <span>{important && <p className="red-D52">*</p>}</span>
        </div>
        <input
          readOnly={readOnly}
          className={inputClass}
          type="number"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />{" "}
        {error && <p className="error">{error}</p>}
      </div>
    );
  }

  if (type === "dropdown") {
    if (multiple) {
      return (
        <>
          <MultiSelect
            value={value}
            onChange={onChange}
            options={options}
            important={important}
            info={info}
            heading={heading}
            placeholder={placeholder}
          />
          {error && <p className="error">{error}</p>}
        </>
      );
    }
    return (
      <>
        <SingleSelect
          value={value}
          onChange={onChange}
          info={info}
          options={options}
          important={important}
          heading={heading}
          placeholder={placeholder}
        />
        {error && <p className="error">{error}</p>}
      </>
    );
  }

  if (type === "file") {
    const handleFileButtonClick = () => {
      // Trigger the file input when custom button is clicked
      fileInputRef.current.click();
    };

    return (
      <div>
        <div className="flex-row">
          <p className="manrope-regular f-s-14 mb-0 p-1 flex-row align-center gap-6">
            {heading && `${heading}`}
            {info && (
              <Tooltip className="f-s-10 ps-1" title={info}>
                {
                  <span className="ps-1">
                    <img src={IIcon} alt={"i"} />
                  </span>
                }
              </Tooltip>
            )}
          </p>
          <span>{important && <p className="red-D52">*</p>}</span>
        </div>
        {/* Hidden file input */}
        <input
          ref={fileInputRef}
          style={{ display: "none" }} // Hide the native input
          accept={accept}
          type="file"
          onChange={onChange}
        />
        {/* Custom button to trigger file selection */}
        <div className="custom-file-button" onClick={handleFileButtonClick}>
          {!value ? (
            <input
              className={inputClass}
              readOnly
              placeholder={placeholder || "Choose File"}
            />
          ) : (
            <input className={inputClass} readOnly value={value.name} />
          )}
        </div>
        {error && <p className="error">{error}</p>}
        {tip && <p className="error black f-s-10 absolute">{tip}</p>}
      </div>
    );
  }

  return (
    <div>
      <div className="flex-row align-center">
        <p className="manrope-regular flex-row f-s-14 mb-0 p-1">
          {heading && `${heading}`}
        </p>
        <span>{important && <p className="red-D52">*</p>}</span>
        {info && (
          <Tooltip className="f-s-10 ps-1" title={info}>
            {
              <span>
                <img src={IIcon} alt={"i"} />
              </span>
            }
          </Tooltip>
        )}
      </div>
      {type === "text-area" ? (
        <>
          <textarea
            readOnly={readOnly}
            className={"textarea-input"}
            cols={cols}
            rows={rows || "5"}
            type="text"
            onChange={onChange}
            value={value}
            maxLength={maxLength || 10000000}
            placeholder={placeholder}
          />
          {error && <p className="error">{error}</p>}
        </>
      ) : (
        <>
          <input
            readOnly={readOnly}
            className={inputClass}
            type="text"
            onChange={onChange}
            value={value}
            placeholder={placeholder}
          />
          {error && <p className="error">{error}</p>}
        </>
      )}
    </div>
  );
};

export default Input;
