import * as React from "react";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import { modals } from "../Constant";
import WelcomeUser from "./modals/WelcomeUser";
import { useSelector } from "react-redux";
import Notification from "./modals/Notification";
import ReferModal from "./modals/ReferModal";
import InviteMember from "./modals/InviteMember";
import EditMember from "./modals/EditMember";
import EditCompany from "./modals/EditCompany";
import BookMeeting from "./modals/BookMeeting";
import HomeAds from "./modals/HomeAds";
import ProfileOptions from "./modals/ProfileOptions";
import EditPassword from "./modals/EditPassword";

const { EDIT_PROFILE_OPTIONS_MODAL, EDIT_PASSWORD_MODAL, HOME_ADS_MODAL, BOOK_MEEETING_MODAL, EDIT_MEMBER_PROFILE_MODAL, EDIT_COMPANY_PROFILE_MODAL, HOME_WELCOME_MODAL, HOME_NOTIFICATION_MODAL, INVITE_CO_MEMBER_MODAL, INVITE_REFER_MODAL } = modals

export default function BasicModal({
    open,
    handleClose,
    children,
    className,
    hideCloseButton,
    closeOnBackdropClick,
    back,
    next,
    data
}) {
    const renderContent = (open) => {
        switch (open) {
            case HOME_WELCOME_MODAL:
                return <WelcomeUser />;
            case HOME_NOTIFICATION_MODAL:
                return <Notification />;
            case INVITE_REFER_MODAL:
                return <ReferModal back={back} />;
            case INVITE_CO_MEMBER_MODAL:
                return <InviteMember back={back} />;
            case EDIT_MEMBER_PROFILE_MODAL:
                return <EditMember />;
            case EDIT_COMPANY_PROFILE_MODAL:
                return <EditCompany />;
            case EDIT_PROFILE_OPTIONS_MODAL:
                return <ProfileOptions />;
            case EDIT_PASSWORD_MODAL:
                return <EditPassword />;
            case BOOK_MEEETING_MODAL:
                return <BookMeeting data={data} />;
            case HOME_ADS_MODAL:
                return <HomeAds />;
            default:
                return children;
        }
    };
    const { modal } = useSelector((state) => state.HomeState)

    return (
        <div>
            <Modal
                open={Boolean(open)}
                onClose={(event, reason) => {
                    if (reason === "backdropClick" && closeOnBackdropClick) {
                        handleClose();
                    } else if (reason !== "backdropClick") {
                        handleClose();
                    }
                }}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className="modal-container"
            >

                <div
                    id="modal-description"
                    className={`${className || "modal-main"}`}
                >
                    {children || renderContent(modal)}
                    <div
                        className="f-s-19 pointer modal-close"
                        onClick={handleClose}
                    >
                        {!hideCloseButton && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.47032 5.46934C5.61094 5.32889 5.80157 5.25 6.00032 5.25C6.19907 5.25 6.38969 5.32889 6.53032 5.46934L18.5303 17.4693C18.604 17.538 18.6631 17.6208 18.7041 17.7128C18.7451 17.8048 18.7671 17.9041 18.7689 18.0048C18.7707 18.1055 18.7522 18.2055 18.7144 18.2989C18.6767 18.3923 18.6206 18.4772 18.5494 18.5484C18.4781 18.6196 18.3933 18.6757 18.2999 18.7135C18.2065 18.7512 18.1065 18.7697 18.0058 18.7679C17.9051 18.7662 17.8058 18.7441 17.7138 18.7031C17.6218 18.6621 17.539 18.603 17.4703 18.5293L5.47032 6.52934C5.32987 6.38871 5.25098 6.19809 5.25098 5.99934C5.25098 5.80059 5.32987 5.60997 5.47032 5.46934Z" fill="black" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5298 5.46934C18.6703 5.60997 18.7492 5.80059 18.7492 5.99934C18.7492 6.19809 18.6703 6.38871 18.5298 6.52934L6.52985 18.5293C6.38767 18.6618 6.19963 18.7339 6.00532 18.7305C5.81102 18.7271 5.62564 18.6484 5.48822 18.511C5.35081 18.3735 5.2721 18.1882 5.26867 17.9939C5.26524 17.7996 5.33737 17.6115 5.46985 17.4693L17.4698 5.46934C17.6105 5.32889 17.8011 5.25 17.9998 5.25C18.1986 5.25 18.3892 5.32889 18.5298 5.46934Z" fill="black" />
                        </svg>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
}
