import { toast } from "react-toastify";
import postLoginInstance from "../../utils/PostLoginAxios";
import { CLEAR_ERRORS, GET_EVENT_DETAILS_FAILURE, GET_EVENT_DETAILS_REQUEST, GET_EVENT_DETAILS_SUCCESS, GET_EVENTS_FAILURE, GET_EVENTS_REQUEST, GET_EVENTS_SUCCESS } from "./ActionTypes";

export const getAllEvents = () => {
    return async (dispatch) => {
        dispatch({ type: GET_EVENTS_REQUEST });
        try {
            const response = await postLoginInstance.get("/event/current-events");
            dispatch({ type: GET_EVENTS_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: GET_EVENTS_FAILURE });
            toast.error(error.response.data.message || "Something went wrong");
            dispatch({ type: CLEAR_ERRORS });
        }
    };
}

export const getEventDetails = ({ uuid }) => {
    return async (dispatch) => {
        dispatch({ type: GET_EVENT_DETAILS_REQUEST });
        try {
            const params = {
                eventUuid: uuid
            };
            const response = await postLoginInstance.get(`/event/event-details`, { params });
            dispatch({ type: GET_EVENT_DETAILS_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: GET_EVENT_DETAILS_FAILURE });
            toast.error(error.response.data.message || "Something went wrong");
            dispatch({ type: CLEAR_ERRORS });
        }
    };
}