import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import emailIcon from "../../../assets/images/mail.svg";
import passwordIcon from "../../../assets/images/password.svg";
import passwordEyeIcon from "../../../assets/images/password-open-eye.svg";
import paswordEyeCloseIcon from "../../../assets/images/ph_eye-closed.svg"; // Eye close icon
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { emailRegex } from "../../../utils/Constant";
import { forgotPasswordSendLink, resetPassword } from "../OnboardingAction";
import { useDispatch } from "react-redux";

const Login = ({ setScreen }) => {
    const [verifyOtp, setVerifyOtp] = useState(false);
    const params = new URLSearchParams(useLocation().search);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showCNFPassword, setShowCNFPassword] = useState(false);
    const [error, setError] = useState({ email: false, password: false, confirmPassword: false });
    const [timer, setTimer] = useState(30);
    const [resendDisabled, setResendDisabled] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (email.match(emailRegex)) setError((err) => ({ ...err, email: false }));
        if (password.length >= 6) setError((err) => ({ ...err, password: false }));
        if (confirmPassword === password) setError((err) => ({ ...err, confirmPassword: false }));
    }, [email, password, confirmPassword]);

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.key === 'Enter' && !verifyOtp && email.length > 0 && !resendDisabled) {
                handleSendLink();
            }
            if (e.key === 'Enter' && verifyOtp && password.length > 0 && confirmPassword.length > 0) {
                handleSubmit();
            }
        }

        window.addEventListener('keydown', handleEnter);
        return () => {
            window.removeEventListener('keydown', handleEnter);
        }

    }, [email, password, confirmPassword]);
    const handleSendLink = () => {
        if (email.length === 0) {
            toast.error("Please fill all the fields");
            return;
        }
        if (!email.match(emailRegex)) {
            setError({ ...error, email: true });
            return;
        }
        dispatch(forgotPasswordSendLink({ email }));
        setResendDisabled(true);
        setTimer(30);
    };

    useEffect(() => {
        let countdown;
        if (resendDisabled && timer > 0) {
            countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setResendDisabled(false);
        }
        return () => clearInterval(countdown);
    }, [resendDisabled, timer]);

    useEffect(() => {
        params.get("token") && setVerifyOtp(true);
        return () => {
            setVerifyOtp(false);
        };
    }, [params]);

    const handleSubmit = () => {
        if (password.length < 6) {
            setError({ ...error, password: true });
            return;
        }
        if (confirmPassword !== password) {
            setError({ ...error, confirmPassword: true });
            return;
        }
        dispatch(resetPassword({ token: params.get("token"), password }));
    };

    if (verifyOtp) {
        return (
            <div className="forgot-main-wrapper">
                <div className="login-head">
                    <h3 className="f-s-30 urbanist-bold">Forgot Password</h3>
                    <p className="f-s-16 manrope-medium forgot-content">Set New Password</p>
                </div>
                <div className="input-main flex-col gap-22">
                    <div>
                        <TextField
                            sx={{ width: "100%" }}
                            id="password"
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            error={error.password}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={passwordIcon} className="p-1" alt="password" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <div className="pointer" onClick={() => setShowPassword((prev) => !prev)}>
                                            <img src={showPassword ? passwordEyeIcon : paswordEyeCloseIcon} className="p-1" alt="toggle visibility" />
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                        {error.password && <p className="error">Password must be at least 6 characters</p>}
                    </div>
                    <div>
                        <TextField
                            sx={{ width: "100%" }}
                            id="confirm-password"
                            placeholder="Confirm Password"
                            type={showCNFPassword ? "text" : "password"}
                            error={error.confirmPassword}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={passwordIcon} className="p-1" alt="confirm password" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <div className="pointer" onClick={() => setShowCNFPassword((prev) => !prev)}>
                                            <img src={showCNFPassword ? passwordEyeIcon : paswordEyeCloseIcon} className="p-1" alt="toggle visibility" />
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                        {error.confirmPassword && <p className="error">Passwords do not match</p>}
                    </div>
                </div>
                <div>
                    <Button className="primary-btn login-button" onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
                <div className="text-center">
                    <p className="f-s-14 manrope-medium underline pointer" onClick={() => { setScreen("login"); navigate("/login") }}>
                        Go back to log in
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="forgot-main-wrapper">
            <div className="login-head">
                <h3 className="f-s-30 urbanist-bold w-fit m-auto">Forgot Password</h3>
                <p className="f-s-16 manrope-medium forgot-content">
                    Enter your email ID below to receive the password reset link
                </p>
            </div>
            <div className="input-main flex-col gap-32">
                <div>
                    <TextField
                        sx={{ width: "100%" }}
                        value={email}
                        error={error.email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="input-with-icon-textfield"
                        placeholder="Enter Email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={emailIcon} className="p-1" alt="email" />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    {error.email && <p className="error">Invalid or incorrect email ID</p>}
                </div>
            </div>
            <div>
                <Button
                    sx={{ width: "100%" }}
                    disabled={resendDisabled}
                    className={`primary-btn login-button ${resendDisabled ? "disabled" : ""}`}
                    onClick={handleSendLink}
                >
                    {resendDisabled ? `resend in ${timer}` : "Submit"}
                </Button>
            </div>
            <div className="forgot-link flex">
                <p className="f-s-14 manrope-medium pointer" onClick={() => setScreen("login")}>
                    Back
                </p>

            </div>
        </div>
    );
};

export default Login;
