import { toast } from "react-toastify";
import { SET_SEARCH } from "./ActionTypes";
import postLoginInstance from "../../utils/PostLoginAxios";


export const getSearch = (data) => {
    return async (dispatch) => {
        try {
            const params = { searchData: data };
            const res = await postLoginInstance.get("/users/basic-search", { params });
            dispatch({ type: SET_SEARCH, payload: res.data });
        } catch (error) {
            toast.error("Something went wrong");
        }
    };
};

export const getAdvancedSearch = (data) => {
    return async (dispatch) => {
        try {
            const params = { ...data };
            const res = await postLoginInstance.get("/users/advance-search", { params });
            dispatch({ type: SET_SEARCH, payload: res.data });
        } catch (error) {
            toast.error("Something went wrong");
        }
    };
}
