import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "./ManageToken";
import { getUserProfile, showModal } from "../app/home/HomeAction";
import { modals } from "./Constant";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "./commonComponents/Modal";
import { Spinner } from "react-bootstrap";

const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // null to indicate loading state
    const dispatch = useDispatch();
    const { user, modal } = useSelector((state) => state.HomeState);

    useEffect(() => {
        const token = getToken();
        if (token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated && !user?.role) {
            dispatch(getUserProfile());
        }
    }, [dispatch, isAuthenticated, user?.role]);

    useEffect(() => {
        if (user.role && user.role === "member" && (!user?.member?.profileImageUrl)) {
            dispatch(showModal(modals.HOME_WELCOME_MODAL));
        }
    }, [dispatch, user]);

    // If still determining authentication state, show loading or nothing
    if (isAuthenticated === null) {
        return <div className="flex-row justify-center align-center h-full"><Spinner /></div>;  // or a spinner/loading component
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            {modal === modals.HOME_WELCOME_MODAL && (
                <BasicModal
                    className="home-welcome-user-modal"
                    open={modal}
                    closeOnBackdropClick={false}
                    hideCloseButton
                />
            )}
            <div>{children}</div>
        </>
    );
};

export default PrivateRoute;
