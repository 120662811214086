import { CLEAR_ERRORS, RESET_SEND_MEETING, GET_COMPANY_FAILURE, GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, GET_MEMBER_FAILURE, GET_MEMBER_REQUEST, GET_MEMBER_SUCCESS, SEND_MEETING_FAILURE, SEND_MEETING_REQUEST, SEND_MEETING_SUCCESS } from "./ActionTypes";
const INITIAL_STATE = {
    member: {
    },
    company: {

    },
    loading: false,
    error: null,
    meeting: {
        sent: false,
        loading: false,
    }
};
const ProfileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_COMPANY_REQUEST:
        case GET_MEMBER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_MEMBER_SUCCESS:
            return {
                ...state,
                member: action.payload.list,
                loading: false
            }
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload,
                loading: false
            }
        case GET_MEMBER_FAILURE:
        case GET_COMPANY_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    sent: false
                }
                ,
                error: null
            }
        case SEND_MEETING_REQUEST:
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    loading: true
                }
            }
        case SEND_MEETING_SUCCESS:
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    sent: true,
                    loading: false
                }
            }
        case RESET_SEND_MEETING:
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    sent: false
                }
            }
        case SEND_MEETING_FAILURE:
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    loading: false
                },
                error: action.payload
            }

        default:
            return state
    }
};
export default ProfileReducer