import { West } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EventsCard from "./EventsCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents } from "../EventsAction";
import dayjs from "dayjs";
import nullimg from "../../../assets/images/not-found.svg";

const Main = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { events } = useSelector((state) => state.EventsState);

  useEffect(() => {
    dispatch(getAllEvents());
  }, []);
  return (
    <>
      <div className="events-main-container ">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">
            {/* <div className="pointer" onClick={() => navigate(-1)}>
              <West sx={{ fontSize: "24px" }}></West>
            </div> */}
            <div>
              <h1 className="f-s-24 manrope-bold">Events</h1>
            </div>
          </div>
        )}

        <div>
          <Row>
            {events.list.data?.map((event) => {
              return (
                <Col md="3" sm="12">
                  <EventsCard
                    tag={
                      <>
                        <div className="f-s-18">
                          {dayjs(event.startDate).format("D")}
                        </div>
                        <div className="f-s-10">
                          {dayjs(event.startDate).format("MMM")}
                        </div>
                      </>
                    }
                    uuid={event.uuid}
                    img={event.mobileImageUrl}
                    heading={event.eventName}
                    text={event.venue}
                  ></EventsCard>
                </Col>
              );
            })}
            {events.list.data?.length === 0 && (
              <div className="flex-col my-5 py-5 flex-1 justify-center align-center">
                <img src={nullimg} alt="" />
                <div className="f-s-14">No events found</div>
              </div>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Main;
