import React from 'react'
import { useSelector } from 'react-redux';
import { NotificationCard } from './Notification';
import CustomButton from '../forms/CustomButton';

const ReceivedNotifications = ({ invite, showInvite, handleWithdraw, getImg }) => {
    const { pastNotifications } = useSelector(state => state.HomeState)
    const uniqueMessages = [...new Map(pastNotifications.received.map((item) => [item.id, item])).values()];
    return (
        <>{
            uniqueMessages && uniqueMessages[0] ? uniqueMessages.map((message, index) => (
                <NotificationCard
                    invite={invite}
                    showInvite={showInvite}
                    data={{
                        recent: false,
                        img: getImg(message),
                        message: (message.notificationType === "super_admin" && message.message),
                        title: message.title,
                        action: (
                            <>
                                {message.notificationType === "super_admin" &&
                                    <></>}
                                {(message.notificationType === "meeting" || message.notificationType === "reschedule") &&
                                    <><CustomButton onClick={() => showInvite(message)} className={"primary-btn"}>View Invite</CustomButton></>}
                                {message.notificationType === "sent_invite" &&
                                    <><CustomButton onClick={() => handleWithdraw(message)} className={"primary-btn bg-grey-F6F black"}>Withdraw</CustomButton></>}

                            </>
                        ),
                    }}
                />
            )) : null

        }
        </>
    )
}

export default ReceivedNotifications
