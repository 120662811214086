import React from "react";
import CustomInput from "../forms/CustomInput";
import { West } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import CustomButton from "../forms/CustomButton";
import BasicModal from "../Modal";
import { showModal } from "../../../app/home/HomeAction";
import { useDispatch } from "react-redux";
import sentIcon from "../../../assets/images/request-sent.svg";
import { emailRegex, phoneRegex } from "../../Constant";
import { toast } from "react-toastify";
import postLoginInstance from "../../PostLoginAxios";

const ReferModal = ({ back }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [sent, setSent] = React.useState(false);
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSendInvite = () => {
    setLoading(true);
    if (email !== "" && emailRegex.test(email) === false) {
      return toast.info("Invalid Email");
    }
    if (phone !== "" && !phoneRegex.test(phone)) {
      return toast.info("Invalid Phone Number");
    }
    try {
      postLoginInstance.post("/company/send-invite", { email, phone });
      setSent(true);
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {sent && (
        <BasicModal
          className={"invite-modal"}
          open={sent}
          handleClose={() => {
            dispatch(showModal(null));
            setSent(false);
          }}
          closeOnBackdropClick
        >
          <Sent />
        </BasicModal>
      )}
      <div className="invite-modal-box">
        <div className="pb-4">
          {isMobile ? (
            <div className="flex-row gap-12 align-center">
              <div onClick={() => back()}>
                <West></West>
              </div>
              <h1 className="f-s-16 manrope-bold">Add co-member</h1>
            </div>
          ) : (
            <h1 className="f-s-24 manrope-bold">Add co-member</h1>
          )}
        </div>
        <div>
          <div className="mb-4">
            <CustomInput
              heading={"Email"}
              placeholder={"Enter Email ID"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={
                email !== "" &&
                emailRegex.test(email) === false &&
                "Invalid Email"
              }
            ></CustomInput>
          </div>
          <div className="mb-4">
            <CustomInput
              heading={"Phone"}
              placeholder={"Enter Phone Number"}
              value={phone}
              type={"number"}
              onChange={(e) => setPhone(e.target.value)}
              error={
                phone !== "" &&
                !phoneRegex.test(phone) &&
                "Invalid Phone Number"
              }
            ></CustomInput>
          </div>
        </div>
        <div>
          <CustomButton
            className={"common-btn mt-4"}
            onClick={handleSendInvite}
            disabled={loading}
          >
            Invite
          </CustomButton>
        </div>
      </div>
    </>
  );
};

export default ReferModal;

const Sent = () => {
  return (
    <div className="flex-col gap-10 justify-center align-center p-4">
      <img src={sentIcon} alt="" />
      <h1 className="f-s-24 manrope-bold">Request Sent</h1>
    </div>
  );
};
