import React, { useState } from "react";
import logo from "../../../assets/images/ipmmi-logo.svg";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";

const ResponsiveMain = () => {
  const [screen, setScreen] = useState("login");

  return (
    <div className="login-main">
      <div className="login-bg flex-col align-center">
        <div className="login-bg-image">
        </div>
      </div>

      <div className="login-main-container">
        <div className="login-main-content">
          <div className="bg-white round w-fit m-auto mb-4 onboarding-logo">
            <img src={logo} width={"61px"} alt="" />
          </div>
          <div className="login-bg-text flex-col gap-10 text-center">
            <h3 className="f-s-20 manrope-extrabold white">
              Join the Future of Packaging: Connect, Collaborate, Grow
            </h3>
            <p className="f-s-14 manrope-regular white">
              Meet fellow industry stalwarts. Network to multiply business
              success. Maximise visibility. Stay updated with IPMMI.
            </p>
          </div>
          {screen === "login" ? (
            <Login setScreen={setScreen} />
          ) : (
            <ForgotPassword setScreen={setScreen} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveMain;
