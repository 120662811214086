import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Button from './forms/CustomButton';

const ImageCropper = ({
  imageSrc,             
  aspect = 1,       
  onCropComplete,       
  onCancel,             
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleShowCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      if (onCropComplete) {
        onCropComplete(croppedImage); // Send cropped image data back to parent
      }
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, onCropComplete]);

  useEffect(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, [imageSrc]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',width:"90vh", maxWidth:"80vw" }}>
      {imageSrc ? (
        <div style={{ position: 'relative', width: '100%', height: 400 }}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
          />
        </div>
      ) : (
        <p>No image provided</p>
      )}

     

      {imageSrc && (
        <div style={{ display: 'flex', gap: '10px',margin:"1rem" }}>
          <Button className={"primary-btn"} color="primary" onClick={handleShowCroppedImage}>
            Crop Image
          </Button>
          <Button
            className={"primary-btn"}
            color={"red-F15 bg-transparent"}
            onClick={onCancel ? onCancel : () => {}}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

export default ImageCropper;

async function getCroppedImg(imageSrc, crop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject('Canvas is empty');
        return;
      }
      const fileUrl = URL.createObjectURL(blob);
      resolve(fileUrl);
    }, 'image/jpeg');
  });
}

function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
}
