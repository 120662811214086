import { setToken } from "../../utils/ManageToken";
import { LOGIN_REQUEST, LOGIN_FAILURE, LOGIN_SUCCESS, FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, CLEAR_ERRORS, LOGOUT } from "./ActionTypes";
const INITIAL_STATE = {
    rememberPassword: false,
    loading: false,
    error: null,
    token: null
};
const OnboardingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
        case FORGOT_PASSWORD_REQUEST:
            return { ...state, loading: true, error: null, token: null };
        case LOGIN_SUCCESS:
            setToken(action.payload.token);
            return { ...state, loading: false, error: null, token: action.payload.token };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, error: null };
        case LOGIN_FAILURE:
        case FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false, error: action.payload, token: null };
        case LOGOUT:
            return { ...state, token: null };
        case CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state
    }
};
export default OnboardingReducer