import postLoginInstance from "../../utils/PostLoginAxios";
import { GET_COMPANY_FAILURE, GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, GET_MEMBER_FAILURE, GET_MEMBER_REQUEST, GET_MEMBER_SUCCESS, RESET_SEND_MEETING, SEND_MEETING_FAILURE, SEND_MEETING_REQUEST, SEND_MEETING_SUCCESS } from "./ActionTypes";

export const getMemberbyId = ({ uuid }) => {
    return async (dispatch) => {
        dispatch({ type: GET_MEMBER_REQUEST });
        try {
            const params = {
                uuid
            };
            const response = await postLoginInstance.get(`/members/view-details`, { params });
            dispatch({ type: GET_MEMBER_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: GET_MEMBER_FAILURE, payload: error.response.data });
        }
    };
}

export const getCompanyById = ({ uuid }) => {
    return async (dispatch) => {
        dispatch({ type: GET_COMPANY_REQUEST });
        try {
            const params = {
                uuid
            };
            const response = await postLoginInstance.get(`/company/view-details`, { params });
            dispatch({ type: GET_COMPANY_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: GET_COMPANY_FAILURE, payload: error.response.data });
        }
    };
}

export const bookMeeting = (data) => {
    return async (dispatch) => {
        dispatch({ type: SEND_MEETING_REQUEST });
        try {
            const response = await postLoginInstance.post(`/meetings/book-meeting`, data);
            dispatch({ type: SEND_MEETING_SUCCESS, payload: response.data });
            setTimeout(() => {
                dispatch({ type: RESET_SEND_MEETING });
            }, 2000);
        } catch (error) {
            dispatch({ type: SEND_MEETING_FAILURE, payload: error.response.data });
        }
    };
}
