import React, { useRef, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HomeAds = ({ ads, close }) => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const carouselRef = useRef(null);
    const navigate = useNavigate();
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [showSkipButton, setShowSkipButton] = useState(true);
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
        // Check if the ad has already been shown during this session
        const hasSeenAd = sessionStorage.getItem('hasSeenAd');

        if (hasSeenAd) {
            // If ad was already shown, immediately close the modal
            close();
            return;
        }

        // Timer for showing the close button
        const timer = setTimeout(() => {
            setShowCloseButton(true);
            setShowSkipButton(false);
        }, 10000);  // Show the close button after 10 seconds

        // Countdown logic for skip button
        const countdownInterval = setInterval(() => {
            setCountdown(prev => {
                if (prev === 1) {
                    clearInterval(countdownInterval);
                }
                return Math.max(prev - 1, 0);
            });
        }, 1000);

        return () => {
            clearTimeout(timer);
            clearInterval(countdownInterval);
        };
    }, [close]);

    const handleVideoEnded = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const handleClick = (hyperLink) => {
        if (hyperLink.startsWith('http')) {
            window.open(hyperLink, '_blank');
        } else {
            navigate(hyperLink);
        }
    };

    return (
        <div className='position-relative'>
            <Carousel ref={carouselRef} className='w-full h-full' controls={false} indicators={false}>
                {ads.map((ad, index) => (
                    <Carousel.Item key={index} className='w-full h-full'>
                        <div
                            onClick={() => handleClick(ad.hyperLink)}
                            className='w-full h-full cursor-pointer'
                        >
                            <video
                                autoPlay
                                muted
                                loop
                                playsInline
                                preload="auto"
                                className='w-100 h-100 object-fit-cover'
                                onEnded={handleVideoEnded}
                            >
                                <source src={isMobile ? ad.mobileVideoUrl : ad.desktopVideoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>

            {showCloseButton && (
                <button
                    onClick={() => { close(); sessionStorage.setItem('hasSeenAd', true) }}
                    className='bg-grey-808 f-s-14 white px-4 border-transparent absolute top-0 end-0 m-2'
                >
                    Skip
                </button>
            )}

            {showSkipButton && (
                <button
                    className='bg-grey-808 f-s-14 white px-4 border-transparent absolute top-0 end-0 m-2'
                >
                    Skip Ads ({countdown})
                </button>
            )}
        </div>
    );
}

export default HomeAds;
