import React, { useState, useRef } from "react";
import { Popper, Fade, Box, Button } from "@mui/material";

const Tooltip = ({ title, children }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* Trigger Element */}
      <div
        ref={anchorRef}
        onMouseEnter={handleOpen} // Open on hover
        onMouseLeave={handleClose} // Close on hover out
        onClick={() => setOpen((prev) => !prev)} // Toggle on click for mobile
        style={{ display: "inline-block", cursor: "pointer" }}
      >
        {children}
      </div>

      {/* Tooltip */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="top-start"
        transition
        disablePortal // Ensure it stays within the bounds of parent containers
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Box
              sx={{
                bgcolor: "white",
                color: "gray",
                p: 1,
                borderRadius: 1,
                fontSize: "1.4rem",
                boxShadow: 3,
                maxWidth: "200px",
                textAlign: "center",
              }}
            >
              {title}
            </Box>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default Tooltip;
