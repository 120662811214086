import React from "react";
import CompanyProfile from "./ProfileCard";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useMediaQuery } from "@mui/material";
import { showModal } from "../../home/HomeAction";
import { modals } from "../../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../utils/commonComponents/Modal";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

const Main = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const { modal, user } = useSelector((state) => state.HomeState);

  return (
    <>
      {modal === modals.EDIT_PROFILE_OPTIONS_MODAL && (
        <BasicModal
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      {modal === modals.EDIT_MEMBER_PROFILE_MODAL && (
        <BasicModal
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      {modal === modals.EDIT_PASSWORD_MODAL && (
        <BasicModal
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      <div className="my-profile-container ">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">
            <div>
              <h1 className="f-s-24 manrope-bold">Profile</h1>
            </div>
          </div>
        )}
        {user.member && (
          <CompanyProfile
            img={user?.member?.profileImageUrl}
            heading={`${user?.member?.firstName} ${user?.member?.lastName}`}
            text={`${user?.member?.designation} | ${user?.member?.company?.companyName}`}
            phone={user?.member?.phone}
            email={user?.member?.user?.email}
            background={user?.member?.bannerUrl}
            edit={true}
          ></CompanyProfile>
        )}
        <div className="my-profile-btn">
          <Row>
            <Col md="7">
              <Row>
                <Col className="my-1">
                  {user?.member?.aboutSection && (
                    <>
                      <h3 className="f-s-16 manrope-medium">About</h3>
                      <p className="f-s-14 my-2 manrope-regular">
                        {user?.member?.aboutSection}
                      </p>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md="3"></Col>
            <Col md="2">
              {" "}
              <CustomButton
                className={"primary-btn"}
                onClick={() =>
                  dispatch(showModal(modals.EDIT_PROFILE_OPTIONS_MODAL))
                }
              >
                Edit Profile
              </CustomButton>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Main;
