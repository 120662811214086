import React from "react";
import Main from "./components/Main";
import AppInterface from "../../utils/commonComponents/AppInterface";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";

const ComponentName = (props) => {
  const navigate = useNavigate();
  return (
    <AppInterface
      header={
        <div className="networking-header flex-row gap-12 align-center">
          <div>
            <h1 className="f-s-16 manrope-bold">Invite</h1>
          </div>
        </div>
      }
    >
      <Main></Main>
    </AppInterface>
  );
};

export default ComponentName;
