import React, { useEffect } from "react";
import AppInterface from "../../utils/commonComponents/AppInterface";
import Main from "./components/Main";
import CompanyMain from "./components/CompanyMain";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { West } from "@mui/icons-material";

const ComponentName = (props) => {
  const { user } = useSelector((state) => state.HomeState);
  const [role, setRole] = React.useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setRole(user?.role);
  }, [user]);
  return (
    <AppInterface header={<div className="networking-header flex-row gap-12 align-center">

      <div>
        <h1 className="f-s-16 manrope-bold">Profile</h1>
      </div>
    </div>}>
      {role === "company" ? <CompanyMain /> : <Main></Main>}
    </AppInterface>
  );
};

export default ComponentName;
