import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import invitesvg from "../../../assets/images/invite-svg.svg";
import refersvg from "../../../assets/images/refer-svg.svg";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../HomeAction";
import { modals } from "../../../utils/Constant";

const ReferInvite = () => {
  const dispath = useDispatch();
  const { user } = useSelector((state) => state.HomeState);
  const [role, setRole] = React.useState(null);
  useEffect(() => {
    setRole(user?.role);
  }, [user]);
  return (
    <div className="refer-invite-container ms-lg-3">
      <Col>
        <div className="refer-main">
          <div>
            <img src={refersvg} alt="" />
          </div>
          <div className="text-center flex-col align-center justify-center gap-6">
            <div>
              <h4 className="f-s-14 manrope-bold">Refer and invite </h4>
              <h4 className="f-s-14 manrope-bold">people to IPMMI</h4>
            </div>
            <CustomButton className={"common-btn f-s-16"} onClick={() => dispath(showModal(modals.INVITE_REFER_MODAL))}>Refer</CustomButton>
          </div>
        </div>
      </Col>
      {role === "company" && user?.members?.length < 3 && <Col>
        <div className="invite-main">
          <div>
            <img src={invitesvg} alt="" />
          </div>
          <div className="text-center flex-col align-center  justify-center gap-6">
            <h4 className="f-s-14 manrope-bold">Add Co-members</h4>
            <CustomButton className={"common-btn f-s-16 "} onClick={() => dispath(showModal(modals.INVITE_CO_MEMBER_MODAL))}>Invite</CustomButton>
          </div>
        </div>
      </Col>}
    </div>
  );
};

export default ReferInvite;
