import React from 'react'
import locationIcon from "../../../assets/images/location.svg"
import { useNavigate } from 'react-router-dom';
const EventsCard = ({ heading, text, tag, img, uuid }) => {
    const navigate = useNavigate();
    return (
        <div className='events-card-container relative mb-4 mt-2 pointer' onClick={() => navigate(`/events/${uuid}`)}>
            <div className="event-card-img">
                <img src={img} alt='event' />
            </div>
            <div className="pt-3 content-main">
                <h2 className="f-s-14 text-overflow-ellipsis manrope-medium p-2 px-3">{heading}</h2>
                <p className="f-s-12 text-overflow-ellipsis manrope-bold p-2 px-3 flex-row align-center"><img src={locationIcon} alt='loc' />{text}</p>
            </div>
            <div className="event-tag">{tag}</div>
        </div >
    )
}

export default EventsCard
