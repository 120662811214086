import React, { useEffect } from 'react';
import Main from './components/Main';
import ResponsiveMain from './components/ResponsiveMain';
import { useMediaQuery } from '@mui/material';
import { getToken } from '../../utils/ManageToken';
import { useNavigate } from 'react-router-dom';

const ComponentName = props => {
    const isMobile = useMediaQuery('(max-width: 768px)')
    const navigate = useNavigate();
    useEffect(() => {
        getToken() && navigate('/')
    }, []);
    return (
        isMobile ?
            <div className='onboarding-main-container-mobile'>
                <ResponsiveMain />
            </div> :
            <div className='onboarding-main-container'>
                <Main />
            </div>
    );
};

export default ComponentName;