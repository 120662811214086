import axios from 'axios';
import { getToken, deleteToken } from "./ManageToken";
import { API_URL } from './Constant';

const postLoginInstance = axios.create(
    {
        baseURL: API_URL,
        headers: {
            'Content-Type': 'application/json',
        }
    }
);
postLoginInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
});
postLoginInstance.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 401) {
        deleteToken();
        window.location = "/";
    }
    return Promise.reject(error);
});

export default postLoginInstance;