import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/ipmmi-logo.svg";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import LeftScreen from "./LeftScreen";

const Main = () => {
    const [screen, setScreen] = useState("login");

    return (
        <>
            <div className="login-main-container">
                <LeftScreen />
                {screen === "login" ? (
                    <Login setScreen={setScreen} />
                ) : (
                    <ForgotPassword setScreen={setScreen} />
                )}
            </div>
            <div className="bg-white round w-fit m-auto mb-4 onboarding-logo">
                <img src={logo} alt="" />
            </div>
        </>
    );
};

export default Main;
