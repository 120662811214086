import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useEffect, useRef, useState } from "react";
import IIcon from "../../../assets/images/Iicon.svg";
import Tooltip from "../common/Tooltip";

const MultiSelect = ({ options, heading, placeholder, important, onChange, value,info }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const containerRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (value) {
            const preselectedItems = options.filter(option => value.includes(option.value));
            setSelectedItems(preselectedItems);
        }
    }, [value, options]); // Now listens to both `value` and `options` updates

    // Handle adding new items
    const handleSelect = (option) => {
        const selectedValues = selectedItems.map(item => item.value);
        if (!selectedValues.includes(option.value)) {
            const updatedItems = [...selectedItems, option];
            setSelectedItems(updatedItems);
            onChange(updatedItems.map(item => item.value)); // Pass the values to onChange
        }
    };

    // Handle removing selected items
    const handleRemove = (itemToRemove) => {
        const updatedItems = selectedItems.filter((item) => item.value !== itemToRemove.value);
        setSelectedItems(updatedItems);
        onChange(updatedItems.map(item => item.value));
    };

    // Handle focus and blur events
    const handleFocus = () => {
        setIsFocused(true);
        setShowDropdown(true);
    };

    const handleBlur = (event) => {
        if (!containerRef.current.contains(event.relatedTarget)) {
            setIsFocused(false);
            setShowDropdown(false);
        }
    };

    // Handle clicking outside to close the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsFocused(false);
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Filter options based on the selected items
    const filteredOptions = options.filter((option) =>
        !selectedItems?.find((item) => item.value === option.value)
    );

    return (
        <div className="multi-select-container" ref={containerRef}>
            <div className="flex-row align-center">
                <p className="manrope-regular f-s-14 mb-0 p-1">{heading && `${heading}`}</p>
                <span>{important && <p className="red-D52">*</p>}</span>
                {info &&<Tooltip className="f-s-10 ps-1" title={info}>{<span><img src={IIcon} alt={"i"}/></span>}</Tooltip>}

            </div>
            <div
                className={`multi-select-wrapper ${isFocused ? "focused" : ""}`}
                onClick={handleFocus}
                onBlur={handleBlur}
            >
                {/* Placeholder and selected items */}
                <div className="multi-select-display pointer" onClick={handleFocus}>
                    {selectedItems.length === 0 && <p className="f-s-16 grey-808 manrope-medium">{placeholder}</p>
                    }
                </div>

                {/* Display selected items as chips */}
                {selectedItems.length > 0 && (
                    <div className="selected-items">
                        {selectedItems.map((item) => (
                            <div key={item.value} className="chip ">
                                {item.label}
                                <span className="close-btn" onClick={() => handleRemove(item)}>
                                    &times;
                                </span>
                            </div>
                        ))}
                    </div>
                )}
                <ArrowDropDownIcon className="dropdown-icon" />

                {/* Options dropdown */}
                {showDropdown && (
                    <div className="options-dropdown" ref={dropdownRef}>
                        {filteredOptions.length === 0 ? (
                            <div className="no-options">No options available</div>
                        ) : (
                            filteredOptions.map((option) => (
                                <div
                                    key={option.value}
                                    className="option-item"
                                    onClick={() => handleSelect(option)}
                                >
                                    {option.label}
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MultiSelect;

export const SingleSelect = ({ options, heading, placeholder, important, onChange, value }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        if (value) {
            const selectedOption = options?.find((option) => option.value === value);
            if (selectedOption && selectedOption.value !== selectedItem?.value) {
                setSelectedItem(selectedOption);
            }
        }
    }, [value, options]);

    const handleSelect = (option) => {
        setSelectedItem(option);
        onChange(option.value);
        setShowDropdown(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="single-select-container" ref={containerRef}>
            <div className="flex-row">
                <p className="manrope-regular f-s-14 mb-0 p-1">{heading}</p>
                <span>{important && <p className="red-D52">*</p>}</span>
            </div>
            <div
                className={`single-select-wrapper ${showDropdown ? "focused" : ""}`}
                onClick={() => {
                    setShowDropdown((prev) => !prev);
                    if (selectedItem) {
                        handleSelect("")
                        setShowDropdown(true)
                    }
                }
                }
            >
                <div className="single-select-display pointer">
                    {selectedItem ? (
                        <div className="selected-item">
                            {selectedItem.label}
                        </div>
                    ) : (
                        <p className="f-s-16 grey-808 manrope-medium">{placeholder}</p>
                    )}
                    <ArrowDropDownIcon className="dropdown-icon" />
                </div>

                {showDropdown && !value && (
                    <div className="options-dropdown">
                        {options?.length === 0 ? (
                            <div className="no-options">No options available</div>
                        ) : (
                            options?.map((option) => (
                                <div
                                    key={option.value}
                                    className="option-item"
                                    onClick={() => handleSelect(option)}
                                >
                                    {option.label}
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

