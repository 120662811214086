import React from "react";
import { Col, Row } from "react-bootstrap";
import logo from "../../../../assets/images/ipmmi-logo.svg";
import notificationIocn from "../../../../assets/images/notification.svg";
import notificationFillIcon from "../../../../assets/images/Notification-fill.svg"
import CustomSearch from "../../forms/CustomSearch";
import { showModal } from "../../../../app/home/HomeAction";
import { modals } from "../../../Constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Header = ({hideSearch}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { messages } = useSelector(state => state.HomeState.notifications);
  const uniqueMessages = [...new Map(messages.map((item) => [item.id, item])).values()];
  return (
    <>
      <div className="mobile-header">
        <Row>
          <Col md="12" className="my-4">
            <div className="flex-row justify-space-between align-center">
              <div className="pointer" onClick={() => navigate("/")}>
                <img src={logo} className="logo" alt="logo" />
              </div>
              <div className="relative">
                <img className="relative" src={messages.length > 0 ? notificationFillIcon : notificationIocn} onClick={() => dispatch(showModal(modals.HOME_NOTIFICATION_MODAL))} alt="notification" />
                {uniqueMessages.length > 0 && <div className="notification-count">{uniqueMessages.length}</div>}
              </div>
            </div>
          </Col>
         {!hideSearch&& <Col md="12" className="mb-4">
            <div>
              <CustomSearch onClick={() => navigate("/search")} placeholder={"Search"} width={"100%"} />
            </div>
          </Col>}
        </Row>
      </div>
    </>
  );
};

export default Header;
