import { CLEAR_ERRORS, GET_EVENT_DETAILS_FAILURE, GET_EVENT_DETAILS_REQUEST, GET_EVENT_DETAILS_SUCCESS, GET_EVENTS_FAILURE, GET_EVENTS_REQUEST, GET_EVENTS_SUCCESS } from "./ActionTypes";
const INITIAL_STATE = {
    events: {
        list: { data: [], loading: false, },
        details: { data: {}, loading: false },
        error: null
    },
};
const EventsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_EVENTS_FAILURE:
            return {
                ...state,
                events: {
                    ...state.events,
                    list: {
                        ...state.events.list,
                        loading: false,
                    }
                }
            };
        case GET_EVENTS_REQUEST:
            return {
                ...state,
                events: {
                    ...state.events,
                    list: {
                        ...state.events.list,
                        loading: true,
                    }
                }
            };
        case GET_EVENTS_SUCCESS:
            return {
                ...state,
                events: {
                    ...state.events,
                    list: {
                        ...state.events.list,
                        loading: false,
                        data: action.payload.list
                    }
                }
            };
        case GET_EVENT_DETAILS_REQUEST:
            return {
                ...state,
                events: {
                    ...state.events,
                    details: {
                        ...state.events.details,
                        loading: true,
                    }
                }
            };
        case GET_EVENT_DETAILS_SUCCESS:
            return {
                ...state,
                events: {
                    ...state.events,
                    details: {
                        ...state.events.details,
                        loading: false,
                        data: action.payload.list
                    }
                }
            };
        case GET_EVENT_DETAILS_FAILURE:
            return {
                ...state,
                events: {
                    ...state.events,
                    details: {
                        ...state.events.details,
                        loading: false,
                    },
                    error: action.payload
                }
            };


        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state
    }
};
export default EventsReducer