import Home from "./app/home/Home"
import { Register } from "./app/register"
import { Onboarding } from "./app/onboarding"
import { Networking } from "./app/networking"
import { Invite } from "./app/invite"
import { Profile } from "./app/profile"
import { Events } from "./app/events"
import { Search } from "./app/search"
import ViewProfile from "./app/profile/ViewProfile"
import ViewCompanyProfile from "./app/profile/ViewCompanyProfile"
import { Navigate } from "react-router-dom"
import PrivateRoute from "./utils/PrivateRoute"
import ViewEvents from "./app/events/ViewEvents"
import SearchResults from "./app/search/SearchResults"
import RegisterSuccessful from "./app/register/RegisterSuccessful"

const routes = [
    {
        path: "/",
        element: <PrivateRoute><Home /></PrivateRoute>,
    },
    {
        path: "/register",
        element: <Register />
    },
    {
        path: "/register/success",
        element: <RegisterSuccessful />
    },
    {
        path: "/login",
        element: <Onboarding />
    },
    {
        path: "/networking",
        element: <PrivateRoute><Networking /></PrivateRoute>
    },
    {
        path: "/invite",
        element: <PrivateRoute><Invite /></PrivateRoute>
    },
    {
        path: "/profile",
        element: <PrivateRoute><Profile /></PrivateRoute>
    },
    {
        path: "/profile/:id",
        element: <PrivateRoute><ViewProfile /></PrivateRoute>
    },
    {
        path: "/company/:id",
        element: <PrivateRoute><ViewCompanyProfile /></PrivateRoute>
    }, {
        path: "/events",
        element: <PrivateRoute><Events /></PrivateRoute>
    }, {
        path: "/events/:id",
        element: <PrivateRoute><ViewEvents /></PrivateRoute>
    }, {
        path: "/search",
        element: <PrivateRoute><Search /></PrivateRoute>
    }, {
        path: "/search/*",
        element: <PrivateRoute><SearchResults /></PrivateRoute>
    }
    , {
        path: "*",
        element: <Navigate to={"/"} />
    }

]
export default routes