import React from "react";
const CustomButton = ({ children, onClick, className, color, disabled, width }) => {
    if (disabled) {
        color = "bg-gray-D9D";
    }
    return (
        <button
            onClick={onClick}
            style={{ width: width }}
            className={`rounded-md custom-button ${className} ${color}`}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default CustomButton;
