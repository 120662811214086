import { createBrowserRouter, RouterProvider } from "react-router-dom"
import routes from "./routes"
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import WebSocketNotifications from "./utils/WebSocketNotifications";
import AddToHomeScreen from "./utils/AddToHomeScreen";

const router = createBrowserRouter(routes);

function App() {
  return (
    <div>
      <AddToHomeScreen />
      <WebSocketNotifications />
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
}
export default App;


