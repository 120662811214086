import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function CustomTabs({ options, nullImg, nullText ,activeKey,onSelect}) {
  return (
    <Tabs
      defaultActiveKey={activeKey|| "default"}
      activeKey={activeKey || "default"}
      className="mb-3 tabs-wrapper"
      justify
      onSelect={onSelect}
    >
      {options.map((option, index) => (
        <Tab
          key={index}
          className="tabs-main"
          eventKey={option.eventKey}
          title={option.title}
          disabled={option.disabled || false}
        >
          {option.children !== null ? <div className="tab-inside-content">{option.children}</div> : (
            <div className="w-fit  text-center null-img">
              <img src={nullImg} alt="null" />
              <p className="f-s-16 manrope-medium">{nullText}</p>
            </div>
          )}
        </Tab>
      ))}
    </Tabs>
  );
}

export default CustomTabs;
