import { West } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../app/home/HomeAction";
import { modals } from "../../Constant";
import CustomInput from "../forms/CustomInput";
import CustomButton from "../forms/CustomButton";
import { toast } from "react-toastify";
import postLoginInstance from "../../PostLoginAxios";

const EditPassword = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const [password, setPassword] = React.useState("");
  const [rePassword, setRePassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSave = async () => {
    setLoading(true);
    if (password.length < 6 || rePassword !== password) {
      return toast.info("Please match passwords");
    }
    try {
      await postLoginInstance.patch("users/change-password", {
        newPassword: password,
      });
      toast.success("Password updated successfully");
    } catch (error) {
      toast.error("Failed to update password");
    } finally {
      setPassword("");
      setRePassword("");
      setLoading(false);
      dispatch(showModal(null));
    }
  };
  return (
    <div className="edit-option-modal">
      <div className="flex-row gap-12 align-center">
        <div className="f-s-24 manrope-semibold pointer">
          {isMobile && (
            <div
              onClick={() =>
                dispatch(showModal(modals.EDIT_PROFILE_OPTIONS_MODAL))
              }
            >
              <West sx={{ fontSize: "20px" }} />
            </div>
          )}
        </div>
        <p className="f-s-24 manrope-semibold edit-option-heading">
          Edit Password
        </p>
      </div>
      <div className="my-4">
        <div className="mb-3">
          <CustomInput
            heading={"New Password"}
            placeholder={"Enter New Password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={
              password &&
              password.length < 6 &&
              "Password must be atleast 6 characters"
            }
          ></CustomInput>
        </div>
        <CustomInput
          heading={"Re-enter Password"}
          placeholder={"Re-enter new Password"}
          value={rePassword}
          onChange={(e) => setRePassword(e.target.value)}
          error={rePassword !== password && "Passwords do not match"}
        ></CustomInput>
      </div>
      <div className="text-right">
        <CustomButton
          disabled={loading}
          className={"primary-btn mt-3"}
          onClick={handleSave}
        >
          Save Changes
        </CustomButton>
      </div>
    </div>
  );
};

export default EditPassword;
