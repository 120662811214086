import { SET_SEARCH } from "./ActionTypes";
const INITIAL_STATE = {
    search: {
        member: [],
        company: [],
        loading: false
    }
    , error: null
};
const SearchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SEARCH:
            return {
                ...state,
                search: {
                    ...state.search,
                    member: action?.payload?.members?.list || action.payload?.list?.members || [],
                    company: action?.payload?.companies?.list || action.payload?.list?.companies || []
                }
            }
        default:
            return state
    }
};
export default SearchReducer