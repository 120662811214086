import { CLEAR_ERRORS, SEND_FOR_REGESTRATION_FAILURE, SEND_FOR_REGESTRATION_REQUEST, SEND_FOR_REGESTRATION_SUCCESS } from "./ActionTypes";
const INITIAL_STATE = {
    loading: false,
    sent: false

};
const RegisterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_FOR_REGESTRATION_REQUEST:
            return { ...state, loading: true };
        case SEND_FOR_REGESTRATION_SUCCESS:
            return { ...state, loading: false, sent: true };
        case SEND_FOR_REGESTRATION_FAILURE:
            return { ...state, loading: false, sent: false };
        case CLEAR_ERRORS:
            return { ...state, loading: false, sent: false };
        default:
            return state
    }
};
export default RegisterReducer