import { West } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../app/home/HomeAction";
import { modals } from "../../Constant";
import { logout } from "../../../app/onboarding/OnboardingAction";
import { useNavigate } from "react-router-dom";

const ProfileOptions = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.HomeState);
  const navigate = useNavigate();
  return (
    <div className="edit-option-modal">
      <div className="flex-row gap-12 align-center">
        <div className="f-s-24 manrope-semibold pointer">
          {isMobile && (
            <div onClick={() => dispatch(showModal(null))}>
              <West sx={{ fontSize: "20px" }} />
            </div>
          )}
        </div>
        <p className="f-s-24 manrope-semibold edit-option-heading">
          Edit Profile
        </p>
      </div>
      <div>
        <ProfileOptionsCard
          startIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.5 7.6875H16.3125V5.25C16.3125 4.10625 15.8581 3.00935 15.0494 2.2006C14.2406 1.39185 13.1437 0.9375 12 0.9375C10.8563 0.9375 9.75935 1.39185 8.9506 2.2006C8.14185 3.00935 7.6875 4.10625 7.6875 5.25V7.6875H4.5C4.1519 7.6875 3.81806 7.82578 3.57192 8.07192C3.32578 8.31806 3.1875 8.6519 3.1875 9V19.5C3.1875 19.8481 3.32578 20.1819 3.57192 20.4281C3.81806 20.6742 4.1519 20.8125 4.5 20.8125H19.5C19.8481 20.8125 20.1819 20.6742 20.4281 20.4281C20.6742 20.1819 20.8125 19.8481 20.8125 19.5V9C20.8125 8.6519 20.6742 8.31806 20.4281 8.07192C20.1819 7.82578 19.8481 7.6875 19.5 7.6875ZM8.8125 5.25C8.8125 4.40462 9.14832 3.59387 9.7461 2.9961C10.3439 2.39832 11.1546 2.0625 12 2.0625C12.8454 2.0625 13.6561 2.39832 14.2539 2.9961C14.8517 3.59387 15.1875 4.40462 15.1875 5.25V7.6875H8.8125V5.25ZM19.6875 19.5C19.6875 19.5497 19.6677 19.5974 19.6326 19.6326C19.5974 19.6677 19.5497 19.6875 19.5 19.6875H4.5C4.45027 19.6875 4.40258 19.6677 4.36742 19.6326C4.33225 19.5974 4.3125 19.5497 4.3125 19.5V9C4.3125 8.95027 4.33225 8.90258 4.36742 8.86742C4.40258 8.83225 4.45027 8.8125 4.5 8.8125H19.5C19.5497 8.8125 19.5974 8.83225 19.6326 8.86742C19.6677 8.90258 19.6875 8.95027 19.6875 9V19.5ZM12.9375 14.25C12.9375 14.4354 12.8825 14.6167 12.7795 14.7708C12.6765 14.925 12.5301 15.0452 12.3588 15.1161C12.1875 15.1871 11.999 15.2057 11.8171 15.1695C11.6352 15.1333 11.4682 15.044 11.3371 14.9129C11.206 14.7818 11.1167 14.6148 11.0805 14.4329C11.0443 14.251 11.0629 14.0625 11.1339 13.8912C11.2048 13.7199 11.325 13.5735 11.4792 13.4705C11.6333 13.3675 11.8146 13.3125 12 13.3125C12.2486 13.3125 12.4871 13.4113 12.6629 13.5871C12.8387 13.7629 12.9375 14.0014 12.9375 14.25Z"
                fill="black"
              />
            </svg>
          }
          endIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.98898 4.50228L15.297 11.9993L7.98898 19.4963C7.85814 19.6302 7.78489 19.81 7.78489 19.9973C7.78489 20.1845 7.85814 20.3643 7.98898 20.4983C8.05253 20.5631 8.12838 20.6147 8.21209 20.6498C8.2958 20.685 8.38568 20.7031 8.47648 20.7031C8.56728 20.7031 8.65717 20.685 8.74088 20.6498C8.82459 20.6147 8.90044 20.5631 8.96398 20.4983L16.74 12.5228C16.8765 12.3827 16.9529 12.1949 16.9529 11.9993C16.9529 11.8037 16.8765 11.6158 16.74 11.4758L8.96548 3.50027C8.90189 3.43496 8.82586 3.38305 8.74187 3.3476C8.65788 3.31215 8.56764 3.29389 8.47648 3.29389C8.38532 3.29389 8.29509 3.31215 8.2111 3.3476C8.12711 3.38305 8.05108 3.43496 7.98748 3.50027C7.85664 3.63422 7.78339 3.81403 7.78339 4.00128C7.78339 4.18852 7.85664 4.36833 7.98748 4.50228H7.98898Z"
                fill="black"
              />
            </svg>
          }
          onClick={() => {
            dispatch(showModal(modals.EDIT_PASSWORD_MODAL));
          }}
        >
          Passwords and security
        </ProfileOptionsCard>

        <ProfileOptionsCard
          startIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 11.9999C21.0028 13.7866 20.4714 15.5334 19.474 17.0159C18.6514 18.2429 17.5391 19.2483 16.2356 19.9432C14.932 20.638 13.4772 21.001 12 20.9999C10.5228 21.001 9.06804 20.638 7.76444 19.9432C6.46085 19.2483 5.34858 18.2429 4.526 17.0159C3.74273 15.8483 3.2439 14.5134 3.06951 13.1183C2.89511 11.7232 3.05 10.3066 3.52175 8.98215C3.9935 7.65768 4.769 6.46216 5.78607 5.49142C6.80314 4.52068 8.0335 3.80173 9.37851 3.39221C10.7235 2.98268 12.1458 2.89396 13.5312 3.13317C14.9167 3.37238 16.2269 3.93286 17.3567 4.76969C18.4865 5.60651 19.4046 6.69639 20.0373 7.95197C20.67 9.20755 20.9997 10.5939 21 11.9999Z"
                stroke="black"
              />
              <path
                d="M13.5 9C13.5 9.39782 13.342 9.77936 13.0607 10.0607C12.7794 10.342 12.3978 10.5 12 10.5V11.5C12.3283 11.5 12.6534 11.4353 12.9567 11.3097C13.26 11.1841 13.5356 10.9999 13.7678 10.7678C13.9999 10.5356 14.1841 10.26 14.3097 9.95671C14.4353 9.65339 14.5 9.3283 14.5 9H13.5ZM12 10.5C11.6022 10.5 11.2207 10.342 10.9393 10.0607C10.658 9.77936 10.5 9.39782 10.5 9H9.50001C9.50001 9.3283 9.56467 9.65339 9.69031 9.95671C9.81595 10.26 10.0001 10.5356 10.2322 10.7678C10.7011 11.2366 11.337 11.5 12 11.5V10.5ZM10.5 9C10.5 8.60218 10.658 8.22064 10.9393 7.93934C11.2207 7.65804 11.6022 7.5 12 7.5V6.5C11.337 6.5 10.7011 6.76339 10.2322 7.23223C9.7634 7.70107 9.50001 8.33696 9.50001 9H10.5ZM12 7.5C12.3978 7.5 12.7794 7.65804 13.0607 7.93934C13.342 8.22064 13.5 8.60218 13.5 9H14.5C14.5 8.33696 14.2366 7.70107 13.7678 7.23223C13.2989 6.76339 12.663 6.5 12 6.5V7.5ZM5.16601 17.856L4.68601 17.714L4.60901 17.975L4.78601 18.182L5.16601 17.856ZM18.834 17.856L19.214 18.182L19.391 17.975L19.313 17.714L18.834 17.856ZM9.00001 15.5H15V14.5H9.00001V15.5ZM9.00001 14.5C8.03086 14.4999 7.08757 14.8126 6.31046 15.3917C5.53336 15.9708 4.96297 16.7853 4.68601 17.714L5.64501 17.999C5.86058 17.2767 6.30357 16.6434 6.90806 16.1931C7.51255 15.7428 8.24625 15.4997 9.00001 15.5V14.5ZM12 20.5C10.7733 20.5013 9.56086 20.2364 8.44644 19.7237C7.33201 19.2109 6.34209 18.4625 5.54501 17.53L4.78601 18.182C5.67706 19.2238 6.78344 20.0599 8.02888 20.6327C9.27431 21.2055 10.6292 21.5014 12 21.5V20.5ZM15 15.5C15.7539 15.4998 16.4877 15.743 17.0922 16.1935C17.6967 16.6439 18.1396 17.2775 18.355 18L19.313 17.714C19.036 16.7853 18.4667 15.9708 17.6896 15.3917C16.9124 14.8126 15.9692 14.4999 15 14.5V15.5ZM18.455 17.53C17.6579 18.4625 16.668 19.2109 15.5536 19.7237C14.4392 20.2364 13.2267 20.5013 12 20.5V21.5C13.3709 21.5014 14.7257 21.2055 15.9711 20.6327C17.2166 20.0599 18.323 19.2238 19.214 18.182L18.455 17.53Z"
                fill="black"
              />
            </svg>
          }
          endIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.98898 4.50228L15.297 11.9993L7.98898 19.4963C7.85814 19.6302 7.78489 19.81 7.78489 19.9973C7.78489 20.1845 7.85814 20.3643 7.98898 20.4983C8.05253 20.5631 8.12838 20.6147 8.21209 20.6498C8.2958 20.685 8.38568 20.7031 8.47648 20.7031C8.56728 20.7031 8.65717 20.685 8.74088 20.6498C8.82459 20.6147 8.90044 20.5631 8.96398 20.4983L16.74 12.5228C16.8765 12.3827 16.9529 12.1949 16.9529 11.9993C16.9529 11.8037 16.8765 11.6158 16.74 11.4758L8.96548 3.50027C8.90189 3.43496 8.82586 3.38305 8.74187 3.3476C8.65788 3.31215 8.56764 3.29389 8.47648 3.29389C8.38532 3.29389 8.29509 3.31215 8.2111 3.3476C8.12711 3.38305 8.05108 3.43496 7.98748 3.50027C7.85664 3.63422 7.78339 3.81403 7.78339 4.00128C7.78339 4.18852 7.85664 4.36833 7.98748 4.50228H7.98898Z"
                fill="black"
              />
            </svg>
          }
          onClick={() =>
            dispatch(
              showModal(
                user.role === "company"
                  ? modals.EDIT_COMPANY_PROFILE_MODAL
                  : modals.EDIT_MEMBER_PROFILE_MODAL
              )
            )
          }
        >
          Profile Details
        </ProfileOptionsCard>
        <ProfileOptionsCard
          startIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1227_4331)">
                <path
                  d="M4.66659 3.99935H15.3333V10.5327H16.6666V3.99935C16.6666 3.64573 16.5261 3.30659 16.2761 3.05654C16.026 2.80649 15.6869 2.66602 15.3333 2.66602H4.66659C4.31296 2.66602 3.97382 2.80649 3.72378 3.05654C3.47373 3.30659 3.33325 3.64573 3.33325 3.99935V19.9993C3.33325 20.353 3.47373 20.6921 3.72378 20.9422C3.97382 21.1922 4.31296 21.3327 4.66659 21.3327H15.3333C15.6869 21.3327 16.026 21.1922 16.2761 20.9422C16.5261 20.6921 16.6666 20.353 16.6666 19.9993H4.66659V3.99935Z"
                  fill="#FF5C5C"
                />
                <path
                  d="M18.7734 11.5197C18.6459 11.4105 18.4818 11.3534 18.314 11.3599C18.1463 11.3664 17.9871 11.4359 17.8684 11.5546C17.7496 11.6734 17.6801 11.8325 17.6736 12.0003C17.6671 12.1681 17.7242 12.3321 17.8334 12.4597L20.0868 14.6663H10.4201C10.2433 14.6663 10.0737 14.7366 9.94868 14.8616C9.82366 14.9866 9.75342 15.1562 9.75342 15.333C9.75342 15.5098 9.82366 15.6794 9.94868 15.8044C10.0737 15.9294 10.2433 15.9997 10.4201 15.9997H20.0868L17.8334 18.3063C17.7636 18.3661 17.707 18.4397 17.6669 18.5224C17.6269 18.6051 17.6044 18.6952 17.6009 18.787C17.5973 18.8788 17.6128 18.9703 17.6463 19.0559C17.6798 19.1414 17.7307 19.2191 17.7957 19.2841C17.8606 19.3491 17.9383 19.3999 18.0239 19.4334C18.1094 19.467 18.201 19.4824 18.2928 19.4789C18.3846 19.4753 18.4747 19.4528 18.5574 19.4128C18.6401 19.3728 18.7137 19.3161 18.7734 19.2463L22.6668 15.3797L18.7734 11.5197Z"
                  fill="#FF5C5C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1227_4331">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          }
          onClick={() => {
            dispatch(logout());
            navigate("/login");
          }}
        >
          <p className="error m-0 f-s-16">Log out</p>
        </ProfileOptionsCard>
      </div>
    </div>
  );
};

export default ProfileOptions;

const ProfileOptionsCard = ({
  startIcon,
  endIcon,
  children,
  onClick,
  className,
}) => {
  return (
    <div
      className={`
          profile-card relative ${className}`}
      onClick={onClick}
    >
      {startIcon && <div className="absolute start-icon">{startIcon}</div>}
      {children}
      {endIcon && <div className="absolute end-icon">{endIcon}</div>}
    </div>
  );
};
