import React, { useEffect } from "react";
import CustomTabs from "../../../utils/commonComponents/common/Tabs";
import { Col, Row } from "react-bootstrap";
import ProfileCard from "./ProfileCard";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { West } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { showModal } from "../../home/HomeAction";
import { modals } from "../../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../../utils/commonComponents/Modal";
import { getCompanies, getMembers } from "../NetworkingAction";
import nullimg from "../../../assets/images/not-found.svg";
import CustomSearch from "../../../utils/commonComponents/forms/CustomSearch";

const Main = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { modal } = useSelector((state) => state.HomeState);
  const { members, companies } = useSelector((state) => state.NetworkingState);
  const [current, setCurrent] = React.useState(null);
  const [activeKey, setActiveKey] = React.useState("people");
  useEffect(() => {
    dispatch(getMembers());
    dispatch(getCompanies());
  }, [dispatch]);
  const path = new URLSearchParams(window.location.search).get("tab");

  useEffect(() => {
    if (path) {
      setActiveKey(path);
    }
  }, [path]);
  return (
    <>
      {modal === modals.BOOK_MEEETING_MODAL && (
        <BasicModal
          open={modals.BOOK_MEEETING_MODAL}
          handleClose={() => dispatch(showModal(null))}
          data={{ uuid: current?.user.uuid, user: current }}
          closeOnBackdropClick
          className={"edit-member-modal"}
        />
      )}
      <div className="networking-main ">
        {!isMobile && (
          <div className="networking-header flex-row gap-12 align-center mb-3">
            <div>
              <h1 className="f-s-24 manrope-bold">Networking</h1>
            </div>
          </div>
        )}
        <div className="networking-tabs-box">
          {isMobile && (
            <div className="mb-4">
              <CustomSearch
                onClick={() => navigate("/search")}
                placeholder={"Search"}
                width={"100%"}
              />
            </div>
          )}
          <CustomTabs
            nullImg={nullimg}
            activeKey={activeKey}
            nullText={"No data found"}
            onSelect={(key) => {
              setActiveKey(key);
              navigate(`/networking?tab=${key}`);
            }}
            options={[
              {
                eventKey: "people",
                title: "People",
                children:
                  members?.data?.length > 0 ? (
                    <Row className="tabs-scroll mt-4">
                      {members?.data?.map((member) => {
                        return (
                          <Col md="3" sm="12">
                            <ProfileCard
                              onClick={() =>
                                navigate(`/profile/${member.uuid}`)
                              }
                              img={member?.profileImageUrl}
                              tag={"Employee"}
                              action={
                                <div className="flex-row gap-6">
                                  <CustomButton
                                    onClick={() =>
                                      navigate(`/profile/${member.uuid}`)
                                    }
                                    className={"common-btn"}
                                  >
                                    View Profile
                                  </CustomButton>
                                  <CustomButton
                                    className={"common-btn bg-grey-F2F black"}
                                    onClick={() => {
                                      setCurrent(member);
                                      dispatch(
                                        showModal(modals.BOOK_MEEETING_MODAL)
                                      );
                                    }}
                                  >
                                    Book a Meeting
                                  </CustomButton>
                                </div>
                              }
                              heading={`${member.firstName || "New user"} ${
                                member.lastName || ""
                              }`}
                              text={`${member?.company.companyName} | ${
                                member.designation || ""
                              }`}
                            ></ProfileCard>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : null,
              },
              {
                eventKey: "company",
                title: "Company",
                children: (
                  <Row className="mt-4 tabs-scroll">
                    {companies?.data?.map((company) => {
                      return (
                        <Col md="3" sm="12">
                          <ProfileCard
                            onClick={() => navigate(`/company/${company.uuid}`)}
                            img={company?.profileImageUrl}
                            tag={"Company"}
                            action={
                              <div className="flex-row gap-6">
                                <CustomButton
                                  className={"common-btn"}
                                  onClick={() =>
                                    navigate(`/company/${company.uuid}`)
                                  }
                                >
                                  View Profile
                                </CustomButton>
                                <CustomButton
                                  onClick={() => {
                                    setCurrent(company);
                                    dispatch(
                                      showModal(modals.BOOK_MEEETING_MODAL)
                                    );
                                  }}
                                  className={"common-btn bg-grey-F2F black"}
                                >
                                  Book a Meeting
                                </CustomButton>
                              </div>
                            }
                            heading={company?.companyName}
                            text={`${company?.contactPerson} | ${company?.designation}`}
                          ></ProfileCard>
                        </Col>
                      );
                    })}
                  </Row>
                ),
              },
            ]}
          ></CustomTabs>
        </div>
      </div>
    </>
  );
};

export default Main;
