import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modals } from "../../utils/Constant";
import BasicModal from "../../utils/commonComponents/Modal";
import AppInterface from "../../utils/commonComponents/AppInterface";
import Main from "./components/Main";
import { showModal } from "./HomeAction";
import { useMediaQuery } from "@mui/material";
import { getVidoeAds } from "../onboarding/OnboardingAction";
import HomeAds from "../../utils/commonComponents/modals/HomeAds";
const { HOME_WELCOME_MODAL } = modals;
const ComponentName = (props) => {
  const { modal } = useSelector((state) => state.HomeState);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [ads, setAds] = React.useState([]);

  useEffect(() => {
    getVidoeAds().then((res) => {
      setAds(res.list);
    }
    ).catch((err) => {
    })
    if (ads.length > 0) {
      dispatch(showModal(modals.HOME_ADS_MODAL));
    }
  }, [ads.length, dispatch]);



  return (
    <>
      {modal === modals.INVITE_REFER_MODAL && (
        <BasicModal
          className={"invite-modal"}
          open={modal}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          back={() => dispatch(showModal(null))}
          hideCloseButton={isMobile}
        />
      )}
      {
        modal === modals.HOME_ADS_MODAL && (
          <BasicModal
            className={"home-ads-modal"}
            open={modal}
            handleClose={() => dispatch(showModal(null))}
            closeOnBackdropClick={false}

            back={() => dispatch(showModal(null))}
            hideCloseButton={true}
          >
            <HomeAds close={() => dispatch(showModal(null))} ads={ads} />
          </BasicModal>
        )
      }
      {modal === modals.INVITE_CO_MEMBER_MODAL && (
        <BasicModal
          open={modal}
          className={"invite-modal"}
          handleClose={() => dispatch(showModal(null))}
          closeOnBackdropClick
          back={() => dispatch(showModal(null))}
          hideCloseButton={isMobile}
        />
      )}
      <AppInterface
      hideSearch={isMobile}
      >
        <Main />
      </AppInterface>
    </>
  );
};

export default ComponentName;
