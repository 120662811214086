import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { styled } from '@mui/material';

const StyledDateTimePicker = styled(MobileDateTimePicker)({
    "html": {
        fontSize: "16px",
    },
    ".MuiInputBase-root": {
        width: "100%",
        backgroundColor: "#F2F2F2",
        borderRadius: "20px",
        "input": {
            height: "40px",
            borderRadius: "20px",
            border: "none",
            background: "none",
            outline: "none",
            padding: "0 16px",
            fontSize: "16px",
            font: "Manrope-Regular"
        },
        "&:hover": {
            borderColor: "#0E1596",
        },
        "&.Mui-focused": {
            borderColor: "#0E1596",
        },
    },
    "&.MuiFormControl-root": {
        width: "100%",
        border: "none",
        boxShadow: "none",
        outline: "none"
    },
    "&.MuiPickersDay-root": {
        fontSize: "16px !important",  // Adjust day font size
        width: "36px",  // Larger clickable area for days
        height: "36px", // Match height to width
    },
    "&. MuiButtonBase-root": {
        fontSize: "16px !important",  // Ensure buttons respect the font size
        padding: "12px",  // Padding for buttons (days)
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
        '&:hover fieldset': {
            border: 'none',
        },
        '&.Mui-focused fieldset': {
            border: 'none',
        },
    },
    width: '100%',
});

export default function ResponsiveDateTimePickers({ value, onChange }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDateTimePicker
                value={value}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
}
