import React from "react";
import ipmmiLogo from "../../assets/images/ipmmi-logo.svg";
import MembershipForm from "./components/MembershipForm";

const ComponentName = (props) => {
  return (
    <div className="register-form relative">
      <div className="form-bg-continaer">
        <div className="absolute bg-content text-center ">
          <div className="bg-white round w-fit m-auto mb-4">
            <img src={ipmmiLogo} alt="" />
          </div>
          <h1 className="f-s-32 manrope-extrabold white form-bg-head">
            Membership Form
          </h1>
        </div>
      </div>
      <div className="main-form-wrapper">
        <div className="main-form-contanier">
          <MembershipForm></MembershipForm>
        </div>
      </div>
    </div>
  );
};

export default ComponentName;
