import React from 'react';
import AppInterface from '../../utils/commonComponents/AppInterface';
import Main from './components/Main';


const ComponentName = props => {
    return (
        <AppInterface
            header={
                <div className="networking-header flex-row gap-12 align-center">
                    <div>
                        <h1 className="f-s-16 manrope-bold">Events</h1>
                    </div>
                </div>
            }
        >
            <Main />
        </AppInterface>
    );
};

export default ComponentName;