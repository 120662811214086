import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../../../utils/commonComponents/forms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDefaultImgUrl } from "../../../utils/Constant";
import { useMediaQuery } from "@mui/material";

const Recommendations = () => {
  const { recommendations, companyRecommendations } = useSelector(
    (state) => state.HomeState
  );
  const [activeTab, setActiveTab] = React.useState("people");

  const navigate = useNavigate();
  return (
    <Col className="recommendations-main pb-0">
      <Row>
        <Col className="recommendations-head">
          <div className="manrope-semibold flex-row justify-space-between align-center">
            Recommendations
            <p
              className="f-s-12 manrope-regular blue-0E1 underline pointer"
              onClick={() => navigate("/networking")}
            >
              View All
            </p>
          </div>
          <div className="flex-row align-center recommend-head mt-3">
            <div
              className={"toggle " + (activeTab === "people" ? " active" : "")}
              onClick={() => setActiveTab("people")}
            >
              {activeTab !== "people" ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#0e1596"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z"
                    fill="black"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z"
                    fill="#0e1596"
                  />
                </svg>
              )}
            </div>
            <div
              className={"toggle " + (activeTab === "company" ? " active" : "")}
              onClick={() => setActiveTab("company")}
            >
              {activeTab === "people" ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 19H23V21H1V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V19H19V11H17V9H20C20.2652 9 20.5196 9.10536 20.7071 9.29289C20.8946 9.48043 21 9.73478 21 10V19ZM5 5V19H13V5H5ZM7 11H11V13H7V11ZM7 7H11V9H7V7Z"
                    fill="black"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 19H23V21H1V19H3V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V19H17V9H20C20.2652 9 20.5196 9.10536 20.7071 9.29289C20.8946 9.48043 21 9.73478 21 10V19ZM7 11V13H11V11H7ZM7 7V9H11V7H7Z"
                    fill="#0e1596"
                  />
                </svg>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {activeTab === "people" ? (
            <div className="recommend-container">
              {recommendations?.map((recommendation, index) => (
                <RecommendationsCard
                  key={index}
                  recommendation={recommendation}
                />
              ))}
              {recommendations?.length < 1 && (
                <div className="empty-recommend flex-row align-center justify-center py-4 my-4">
                  <p className="f-s-12 manrope-regular">No Recommendations</p>
                </div>
              )}
            </div>
          ) : (
            <div className="recommend-container">
              {companyRecommendations?.map((recommendation, index) => (
                <RecommendationsCard
                  key={index}
                  recommendation={recommendation}
                />
              ))}
              {companyRecommendations?.length < 1 && (
                <div className="empty-recommend flex-row align-center justify-center py-4 my-4">
                  <p className="f-s-12 manrope-regular">No Recommendations</p>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default Recommendations;

const RecommendationsCard = ({ recommendation }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="recommendation-card flex-row justify-space-between ">
      <div className="recommendation-img">
        <img
          src={
            recommendation.profileImageUrl
              ? recommendation.profileImageUrl
              : getDefaultImgUrl(
                  recommendation?.firstName ||
                    recommendation?.company?.companyName ||
                    recommendation?.contactPerson
                )
          }
          alt="profile"
        />
      </div>
      <div className="recommend-detail-wrapper">
        <div className="gap-2 recommend-detail flex-col align-start justify-center h-full">
          <h5 className="f-s-14 manrope-medium">
            {recommendation?.firstName ||
              recommendation?.companyName ||
              "New User"}
          </h5>
          <div className="f-s-12 manrope-regular ">
            {recommendation?.company?.companyName ||
              recommendation?.contactPerson ||
              ""}
          </div>
        </div>
      </div>
      <div className="p-0">
        <div className="text-center h-full flex-row justify-center align-center">
          <CustomButton
            onClick={() =>
              navigate(
                recommendation?.company
                  ? `/profile/${recommendation.uuid}`
                  : `/company/${recommendation.uuid}`
              )
            }
            className={"common-btn manrope-regular recommendation-btn"}
          >
            {isMobile ? (
              "View Profile"
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.53 11.47C20.6704 11.6107 20.7493 11.8013 20.7493 12C20.7493 12.1988 20.6704 12.3894 20.53 12.53L14.53 18.53C14.4613 18.6037 14.3785 18.6628 14.2865 18.7038C14.1945 18.7448 14.0952 18.7668 13.9945 18.7686C13.8938 18.7704 13.7938 18.7519 13.7004 18.7142C13.607 18.6764 13.5222 18.6203 13.451 18.5491C13.3797 18.4779 13.3236 18.393 13.2859 18.2996C13.2481 18.2062 13.2296 18.1062 13.2314 18.0055C13.2332 17.9048 13.2552 17.8055 13.2962 17.7135C13.3372 17.6215 13.3963 17.5387 13.47 17.47L18.19 12.75H3.99999C3.80108 12.75 3.61031 12.671 3.46966 12.5304C3.32901 12.3897 3.24999 12.1989 3.24999 12C3.24999 11.8011 3.32901 11.6104 3.46966 11.4697C3.61031 11.329 3.80108 11.25 3.99999 11.25H18.19L13.47 6.53003C13.3963 6.46137 13.3372 6.37857 13.2962 6.28657C13.2552 6.19457 13.2332 6.09526 13.2314 5.99455C13.2296 5.89385 13.2481 5.79382 13.2859 5.70043C13.3236 5.60705 13.3797 5.52221 13.451 5.45099C13.5222 5.37977 13.607 5.32363 13.7004 5.28591C13.7938 5.24819 13.8938 5.22966 13.9945 5.23144C14.0952 5.23322 14.1945 5.25526 14.2865 5.29625C14.3785 5.33724 14.4613 5.39634 14.53 5.47003L20.53 11.47Z"
                  fill="black"
                />
              </svg>
            )}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
